/* eslint-disable no-shadow */
import { auth } from '../config/firebase';

const initState = {
  loggedIn: false,
  profile: null
};

export default {
  state: initState,
  reducers: {
    reset: () => ({ ...initState }),
    setUser: (state, data) => ({ ...state, data }),
    saveUser: (state, {
      profile, auth, idToken, identitas
    }) => ({
      ...state, loggedIn: true, profile
    }),
  },
  effects: (dispatch) => ({
    signOut: async () => {
      try {
        console.log('logout');
        await auth.signOut();
        dispatch.user.reset();
      } catch (error) {
        console.log(error);
      }
    },
  })
};
