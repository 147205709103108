import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  RowDetailState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  PagingPanel,
  TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';
import { CircularProgress } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { firestore } from 'src/config/firebase';
import { useSelector } from 'react-redux';

import Page from 'src/components/Page';
import EditButton from './Edit/EditButton';
import SwitchButton from './active/switch';
import DeleteButton from './delete/deleteAdmin';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(1)
  },
  productCard: {
    height: '100%'
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

function Index() {
  const classes = useStyles();
  const user = useSelector(state => state.user.profile)

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizes] = useState([5, 10, 25, 50, 0]);
  const [defaultHiddenColumnNames] = useState(['edit', 'action']);
  const [data, setData] = useState(null);

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const read = (
        await firestore.collection("admin").orderBy('name', 'asc').get()
      ).docs.map(e => e.data())
      setData(read)
    } catch (err) {
      console.log(err);
      alert(err)
    }
  }

  const columns = [
    {
      name: 'edit',
      title: 'Edit',
      getCellValue: (row) => row.isSuperAuthority
                              ? null
                              : <EditButton data={row} />
    },
    {
      name: 'id',
      title: 'ID Admin',
      getCellValue: (row) => row.id
    },
    {
      name: 'name',
      title: 'Nama',
      getCellValue: (row) => row.name
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row) => row.isActive === undefined 
                              ? <ClearIcon color="secondary"/> 
                              : !row.isActive 
                                ? <ClearIcon color="secondary"/> 
                                : <CheckCircleIcon color="primary" />
    },
    {
      name: 'action',
      title: 'Aktif/NonAktif',
      getCellValue: (row) => row.isSuperAuthority
                              ? null
                              : <SwitchButton data={row} user={user} />
    },
    {
      name: 'hapus',
      title: 'Hapus',
      getCellValue: (row) => row.isSuperAuthority
                              ? null
                              : <DeleteButton data={row} user={user} />
    },
  ];

  if (data === null) {
    return (
      <Page
        className={classes.loading}
        title="Pembayaran"
      >
        <CircularProgress color="primary" />
      </Page>
    );
  } else {
    return (
      <Page
        className={classes.root}
        title="Pembayaran"
      >
        <Paper>
          <Grid
            rows={data}
            columns={columns}
          >
            <SearchState />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <RowDetailState />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow />
            {
              user === null
                ? null
                : (
                  user.isSuperAuthority
                    ? null
                    : <TableColumnVisibility
                        defaultHiddenColumnNames={defaultHiddenColumnNames}
                      />
                )
            }
            <Toolbar />
            <PagingPanel
              pageSizes={pageSizes}
            />
            <SearchPanel />
          </Grid>
        </Paper>
      </Page>
    );
  }
}

export default Index;
