import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { useSelector } from 'react-redux';

import { deletePayment, acceptPayment, rejectPayment } from './helper';

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(0.5),
  },
}));

function AcceptButton({ data }) {
  const classes = useStyles();
  const user = useSelector(state => state.user.profile);
  const [reasonReject, setReasonReject] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const accept = async () => {
    setLoading(true);
    await acceptPayment(data, user);
    setLoading(false);
  };
  const reject = async () => {
    setLoading(true);
    await rejectPayment(data, user, reasonReject);
    setLoading(false);
  };
  const paymentDelete = async () => {
    setLoading(true);
    await deletePayment(data, user);
    setLoading(false);
  }
  return (
    <div className={classes.root}>
      <IconButton color="primary" aria-label="upload picture" onClick={handleClick}>
        <AccountBalanceWalletIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={accept}>Terima</MenuItem>
        <MenuItem onClick={() => setOpen(true)}>Tolak</MenuItem>
        <MenuItem onClick={paymentDelete}>Hapus</MenuItem>
      </Menu>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <TextField
            id="reason reject"
            label="Alasan Tolak"
            value={reasonReject}
            onChange={(e) => setReasonReject(e.target.value)}
            fullWidth
            style={{ width: '30vw' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Batal
          </Button>
          <Button onClick={reject} color="primary" autoFocus>
            Tolak
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

AcceptButton.propTypes = {
  data: PropTypes.object
};

export default AcceptButton;
