import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import _ from 'lodash';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch } from 'react-redux';

import Firebase, { auth, firestore, functions } from '../../config/firebase';
import Page from 'src/components/Page';

const urlLogo ="https://firebasestorage.googleapis.com/v0/b/almuhibbin-76834.appspot.com/o/utilities%2Flogo.png?alt=media&token=1048fc3a-8b65-4d03-b82a-09ccadea4317"

const useStyles = makeStyles((theme) => ({
  root: {
    
  },
  image: {
    backgroundImage: 'url(https://almuhibbin.ponpes.id/wp-content/uploads/2022/02/Screen-Shot-2022-02-09-at-11.56.29-PM-1200x786.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [authUser] = useAuthState(auth);

  useEffect(() => {
    if (!_.isEmpty(authUser)) {
      dispatch.user.signOut();
    }
  }, []);

  const login = async (uid, password) => {
    setIsLoading(true);
    const data = {
      uid: uid,
      password: password
    };
    try {
      const signInResponse = await functions.httpsCallable("loginAdmin")(data);
      const login = await auth.signInWithCustomToken(signInResponse.data.token);
      if (login) {
        const adminProfile = await Firebase.firestore().doc(`admin/${login.user.uid}`).get().then((doc) => doc.data());
        const dataSantri = (
          await firestore.collection('santri').orderBy('name', 'asc').get()
        ).docs.map(e => e.data())
        dispatch.user.saveUser({ profile: adminProfile });
        dispatch.santri.setDataSantri({santri: dataSantri})
        navigate('/app/home-dashboard', { replace: true });
      }
    } catch (error) {
      console.log(error);
      alert('Terjadi Kesalahan Saat Login, Pastikan Username dan Password yang Kamu Masukkan Benar!');
      setIsLoading(false);
    }
  };

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Grid container component="main">
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
            >
              <Container maxWidth="sm">
                <Formik
                  initialValues={{
                    uid: '',
                    password: ''
                  }}
                  validationSchema={Yup.object().shape({
                    uid: Yup.string().max(255).required('UID is required'),
                    password: Yup.string().max(255).required('Password is required')
                  })}
                  onSubmit={({ uid, password }) => {
                    login(uid, password);
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box mb={3}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 50}}>
                          <img
                            src={urlLogo}
                            alt="logo"
                            width="300"
                            height="110"
                          />
                        </div>
                        <Typography
                          color="textPrimary"
                          variant="h2"
                        >
                          Login Admin
                        </Typography>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body2"
                        >
                          Masukkan ID Admin dan Password Anda
                        </Typography>
                      </Box>
                      <TextField
                        error={Boolean(touched.uid && errors.uid)}
                        fullWidth
                        helperText={touched.uid && errors.uid}
                        label="Username"
                        margin="normal"
                        name="uid"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="uid"
                        value={values.uid}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Password"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                      />
                      <Box my={2}>
                        <Button
                          color="primary"
                          disabled={isLoading}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Login Sekarang
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Container>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Page>
  );
};

export default LoginView;
