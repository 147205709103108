import React from 'react';
import ReactToPrint from 'react-to-print';

import Page from './page';
import { Button } from '@material-ui/core';

export default class Example extends React.PureComponent {
  render() {
    console.log(this.props.data)
    return (
      <div>
        <ReactToPrint
          trigger={() => {
            return (
              <Button variant="contained" color="primary">
                Cetak
              </Button>
            )
          }}
          content={() => this.componentRef}
        />
        <div style={{display: 'none'}}>
          <Page ref={el => (this.componentRef = el)} data={this.props.data} />
        </div>
      </div>
    );
  }
}