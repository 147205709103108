/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
import {
  Settings as SettingsIcon,
  Users as UsersIcon,
  Home as HomeIcon,
  FileText as FileTextIcon
} from 'react-feather';
import PaymentIcon from '@material-ui/icons/Payment';

export const items = [
  {
    href: '/app/home-dashboard',
    icon: HomeIcon,
    title: 'Dashboard'
  },
  {
    icon: PaymentIcon,
    title: 'Pembayaran',
    sub: [
      {
        title: 'Live Pembayaran',
        href: '/app/payment',
      },
      {
        title: 'Setting Pembayaran',
        href: '/app/settings-payments'
      },
      {
        title: 'Tunggakan Pembayaran',
        href: 'payment/arrears-payments'
      }
    ]
  },
  {
    icon: PaymentIcon,
    title: 'Tabungan',
    sub: [
      {
        title: 'Data Tabungan Santri',
        href: '/app/tabungan-santri'
      },
      {
        title: 'Transaksi Tabungan Santri',
        href: 'tabungan/transaksi-tabungan'
      },
    ]
  },
  {
    icon: PaymentIcon,
    title: 'Uang Saku',
    sub: [
      {
        title: 'Data Saku Santri',
        href: '/app/saku-santri'
      },
      {
        title: 'Transaksi Saku Santri',
        href: 'saku/transaksi-saku'
      },
    ]
  },
  {
    href: '/app/data-santri',
    icon: UsersIcon,
    title: 'Data Santri'
  },
  {
    icon: SettingsIcon,
    title: 'Admin',
    sub: [
      {
        title: 'Data Admin',
        href: 'admin/data-admin'
      },
      {
        title: 'Tambahkan Admin',
        href: 'admin/add-admin'
      },
    ]
  },
  {
    icon: SettingsIcon,
    title: 'Config APK',
    sub: [
      {
        title: 'Banner',
        href: 'banner/page'
      },
      {
        title: 'Customer Sevices',
        href: 'customer-services/page'
      },
    ]
  },
  {
    href: '/app/pengumuman',
    icon: FileTextIcon,
    title: 'Pengumuman'
  },
  // {
  //   href: 'test-data',
  //   icon: UsersIcon,
  //   title: 'Bots'
  // },
];
