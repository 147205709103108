import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
} from '@devexpress/dx-react-grid-material-ui';

const styles = () => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table
    {...restProps}
    className={classes.tableStriped}
  />
);

const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);
export default TableComponent