import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { firestore } from 'src/config/firebase';

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 200,
  },
});

export default function MediaCard({ item }) {
  const classes = useStyles();

  const handleDelete = async () => {
    try {
      await firestore.collection('config').doc('imageSlider')
        .collection('images').doc(`${item.id}`)
        .delete()
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={item.url}
          title="lorem picsum"
        />
      </CardActionArea>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          { item.name }
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant='outlined' size="small" color="secondary" onClick={handleDelete}>
          Hapus
        </Button>
        <Button variant='contained' size="small" color="primary" href={item.url} target='_blank'>
          Lihat
        </Button>
      </CardActions>
    </Card>
  );
}
