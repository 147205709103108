const initState = {
  santri: [],
};

export default {
  state: initState,
  reducers: {
    reset: () => ({ ...initState }),
    setDataSantri: (state, santri) => ({ ...state, santri }),
  }
};
