import React, { useState, useEffect } from 'react'
import { firestore } from 'src/config/firebase';
import { CircularProgress } from '@material-ui/core';

import ArrearsTable from './table';

const Index = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getDataSantri()
  }, []);

  const getDataSantri = async () => {
    try {
      const dataSantri = (
        await firestore.collection('payment').orderBy('createAt', 'asc').get()
      ).docs.map((a) => {
        var item;
        item = a.data();
        item.id = a.id;
        return item
      })
      setData(dataSantri);
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }
  // const SetData = async () => {
  //   console.log('Budal')
  //   try {
  //     const santriData = (
  //       await firestore.collection('santri').get()
  //     )
  //     santriData.forEach(async (doc) => {
  //       const paymentRef = firestore.collection('santri').doc(`${doc.data().noRekening}`)

  //       paymentRef.set({isActive: true}, { merge: true });
  //       console.log(doc.data().noRekening, 'Sukses')
  //     })
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }
  return (
    <div style={{padding: 25}}>
      { data === null 
        ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress />
          </div>
        : <ArrearsTable data={data} /> }
      {/* <Button variant="contained" onClick={SetData}>
        Set Test Data
      </Button> */}
    </div>
  )
}

export default Index
