import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
  },
  media: {
    height: 200,
  },
});

export default function MediaCard({ row }) {
  const classes = useStyles();

  return (
    <div style={{padding: 10}}>
      {
        row.image === null
          ? null
          : <Card className={classes.root}>
              <CardActionArea href={row.image} target='_blank'>
                <CardMedia
                  className={classes.media}
                  image={row.image}
                  title={row.title}
                />
              </CardActionArea>
            </Card>
      }
      <TextField
        autoFocus
        margin="normal"
        label="Konten"
        fullWidth
        variant='outlined'
        multiline
        maxRows={5}
        minRows={5}
        value={`${row.content}`}
        contentEditable={false}
      />  
    </div>
  );
}
