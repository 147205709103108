import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  RowDetailState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  PagingPanel,
  TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';
import {
  makeStyles, CircularProgress
} from '@material-ui/core';
import moment from 'moment';
import { useSelector } from 'react-redux';

import Page from 'src/components/Page';
import { firestore } from 'src/config/firebase';
import InputPayment from './inputPayment';
import Configure from './configurePayments/configurePayments';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(2)
  },
  productCard: {
    height: '100%'
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
}));

function Index() {
  const classes = useStyles();
  const user = useSelector(state => state.user.profile);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizes] = useState([5, 10, 25, 50, 100, 250, 500, 1000, 0]);
  const [defaultHiddenColumnNames] = useState(['action']);
  const [data, setData] = useState(null);

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      await firestore.collection('payment')
        .orderBy('createAt', 'desc')
        .onSnapshot((doc) => {
          const getDataDb = doc.docs.map((item) => {
            let data;
            data = item.data();
            data.id = item.id;
            return data;
          });
          setData(getDataDb);
      })
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }

  const columns = [
    {
      name: 'date',
      title: 'Dibuat Pada',
      getCellValue: (row) => moment(row.createAt.toDate()).format("DD-MM-YYYY")
    },
    {
      name: 'name',
      title: 'Nama',
      getCellValue: (row) => row.name === undefined ? '' : row.name.toUpperCase()
    },
    {
      name: 'keterangan',
      title: 'Keterangan',
      getCellValue: (row) => row.date === undefined ? '' : row.date
    },
    {
      name: 'action',
      title: 'Tindakan',
      getCellValue: (row) => <Configure data={row} />
    },
  ];

  if (data === null) {
    return (
      <Page
        className={classes.loading}
      >
        <CircularProgress color="primary" />
      </Page>
    );
  } else {
    return (
      <Page
        className={classes.root}
        title="Pembayaran"
      >
        {
          user === null
            ? null
            : <InputPayment isAdmin={user.isSuperAuthority} />
        }
        <Paper>
          <Grid
            rows={data}
            columns={columns}
          >
            <SearchState />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <RowDetailState />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow />
            {
              user.isSuperAuthority
                ? null
                : <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                  />
            }
            <Toolbar />
            <PagingPanel
              pageSizes={pageSizes}
            />
            <SearchPanel />
          </Grid>
        </Paper>
      </Page>
    );
  }
}

export default Index;
