import React from 'react'
import { Paper, TextField } from '@material-ui/core';
import { fareFormatter } from 'src/utils/fareFormatter';

const RowDettail = ({ row }) => {
  return (
    <Paper style={{paddingLeft: 25, paddingRight: 25}}>
      <TextField
        label="Nomor Transaksi"
        value={row.id}
        contentEditable={false}
        fullWidth />
      <TextField
        label="Type"
        value={row.type}
        contentEditable={false}
        fullWidth />
      <TextField
        label="Jumlah Nominal"
        value={fareFormatter(row.totalAmount)}
        contentEditable={false}
        fullWidth />
      <TextField
        label="Keterangan"
        value={row.keterangan}
        contentEditable={false}
        fullWidth />
      <TextField
        label="Petugas"
        value={row.tellerData === undefined ? ' - ' : row.tellerData.name}
        contentEditable={false}
        fullWidth />
    </Paper>
  )
}

export default RowDettail
