import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import EditDialog from './editSantriDialog';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

export default function IconButtons({ data}) {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false)

  return (
    <div className={classes.root}>
      <IconButton aria-label="delete" onClick={() => setIsEdit(true)}>
        <EditIcon />
      </IconButton>
      <EditDialog data={data} open={isEdit} handleClose={() => setIsEdit(false)} />
    </div>
  );
}
