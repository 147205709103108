import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { makeid } from 'src/utils/id';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';

import { firestore, storage } from 'src/config/firebase';
import firebase from 'firebase'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 200,
  },
  input: {
    display: 'none',
  },
}));

export default function FormDialog({open, handleClose}) {
  const classes = useStyles();

  // const [name, setname] = useState('');
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState('https://www.tanjunglesung.com/wp-content/uploads/2018/10/placeholder.png')
  const [uploadFile, setUploadFile] = useState(null);

  const handleSave = async () => {
    setLoading(true)
    const id = makeid(12)
    const ref = firestore.collection('config').doc('imageSlider').collection('images').doc(`${id}`)
    try {
      const photoUrl = await saveImage(id)
        await ref.set({
          // name: name,
          url: photoUrl,
          createAt: firebase.firestore.Timestamp.now()
        }, {merge: true})
        alert('Berhasil Menyimpan Data');
        handleClose();
        setLoading(false);
    } catch (error) {
      console.log(error);
      alert(error);
      setLoading(false);
    }
  }

  const onChangeFile = (e) => {
    const file = e.target.files[0]
    console.log(e.target.files[0])
    const image = URL.createObjectURL(file)
    setPhoto(image)
    setUploadFile(e.target.files[0])
  }

  const saveImage = async (id) => {
    try {
      const storageRef = storage.ref(`utilities/imageSlider/${id}.jpg`)
      const uploadTask = await storageRef.put(uploadFile)
      const url = await uploadTask.ref.getDownloadURL();
      return url
    } catch (err) {
      console.log(err);
      alert(`${err}`)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle id="form-dialog-title">Input Data Pengasuh</DialogTitle>
      <DialogContent>
        {/* <TextField
          id="name"
          autoFocus
          margin="normal"
          label="Nama"
          fullWidth
          value={name}
          onChange={(e) => setname(e.target.value)}
          variant='outlined'
        /> */}
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={photo}
              title="Photo Profile"
            />
          </CardActionArea>
          <CardActions>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={onChangeFile}
            />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span" size="small">
                Ambil Foto Dari Galeri
              </Button>
            </label>
          </CardActions>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave} color="primary">
          Simpan
        </Button>
      </DialogActions>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}
