import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import 'moment/locale/id';
import { fareFormatter } from 'src/utils/fareFormatter';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Download = ({ data }) => {
  return (
    <div>
      <label htmlFor="print-excel">
        <Button component="span" fullWidth size="large" variant="contained" color="primary" style={{ marginTop: 20 }}>
          Cetak Laporan
        </Button>
      </label>
      <div style={{display: 'none'}}> 
        <ReactHTMLTableToExcel
          id="print-excel"
          className="download-table-xls-button"
          table="table-to-xls"
          filename={`Laporan-pembayaran-Uang-Saku-${moment().format('DD-MM-YYYY')}`}
          sheet="tablexls"
          buttonText="Download as XLS" />
      </div>
      <table 
        id="table-to-xls" 
        style={{display: 'none'}}>
        <thead>
          <tr>
            <th>Tanggal</th>
            <th>Nomor Transaksi</th>
            <th>Metode Pembayaran</th>
            <th>Petugas</th>
            <th>No. Rekening</th>
            <th>Nama</th>
            <th>Jenis Kelamin</th>
            <th>Sekolah</th>
            <th>Kamar</th>
            <th>Jenis Biaya Siswa</th>
            <th>Rincian Pembayaran</th>
            <th>Total Pembayaran</th>
          </tr>
        </thead>
        <tbody>
        {
          data.map((i, b) => {
            return (
              <tr key={b}>
                <td>{moment(i.createAt.toDate()).format('DD/MM/YYYY')}</td>
                <td>{i.id}</td>
                <td>{`${i.method === undefined ? ' - ' : i.method}`}</td>
                <td>{i.tellerData === undefined ? ' - ' : i.tellerData.name}</td>
                <td>{i.userData.noRekening}</td>
                <td>{i.userData.name}</td>
                <td>{i.userData.kelamin}</td>
                <td>{i.userData.sekolah}</td>
                <td>{i.userData.kamar}</td>
                <td>
                  {`${i.name}`}
                </td>
                <td>
                  {`${i.type}`}
                </td>
                <td>{fareFormatter(i.totalAmount)}</td>
              </tr>
            )
          })
        }
        </tbody>
      </table>
    </div>
  );
};

export default Download;
