import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { firestore } from 'src/config/firebase';
import { CircularProgress, Grid } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import LockIcon from '@material-ui/icons/Lock';
import md5 from 'md5';

import WithDrawScreen from './Dettail';

const CreatePassword = ({ setIsPass, data, loading, setLoading }) => {
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');

  const handleSavePassword = async () => {
    setLoading(true);
    const ref = firestore.collection('santri').doc(`${data.noRekening}`)
    try {
      if (password.length <= 5) {
        console.log('Password Harus Minimal 6 Karakter');
        alert('Password Harus Minimal 6 Karakter');
        setLoading(false);
      } else if (password !== confirm) {
        console.log('password tidak sesuai');
        alert('Konfirmasi Password Tidak Sesuai Dengan Password yang Dimasukkan');
        setLoading(false);
      } else {
        console.log('sukses');
        await ref.set({
          tabungan: {
            updateAt: new Date(),
            hash: md5(password)
          }
        }, { merge: true })
        setLoading(false);
        setIsPass(true);
      }
    } catch (error) {
      alert(error);
      console.log(error);
      setLoading(false);
    }
  }

  console.log(data.noRekening)

  return (
    <div>
      <TextField
        id="create"
        label="Buat Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        autoFocus
        margin="dense"
      />
      <TextField
        id="create"
        label="Konfirmasi Password"
        type="password"
        value={confirm}
        onChange={(e) => setConfirm(e.target.value)}
        fullWidth
        autoFocus
        margin="dense"
      />
      <Button
        variant="contained"
        fullWidth
        color="primary"
        onClick={handleSavePassword}
        disabled={loading}
        startIcon={loading ? <CircularProgress/> : <SaveIcon/>}
      >
        Simpan
      </Button>
    </div>
  )
}

export default function FormDialog({ data, handleClose }) {
  const [isPass, setIsPass] = useState(false);
  const [pass, setPass] = useState('');
  const [loading, setLoading] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  useEffect(() => {
    setPasswordIsTrue()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const setPasswordIsTrue = async () => {
    try {
      await firestore.collection('santri').doc(`${data.noRekening}`)
        .onSnapshot((snap) => {
          const snapData = snap.data()
          if (snapData.tabungan.hash !== undefined) {
            setIsPass(true);
          }
        })
    } catch (err) {
      console.log(err);
      alert('Ada Masalah Dalam Menyiapkan Auth!')
    }
  }

  const handlePass = async () => {
    setLoading(true);
    try {
      const passUser = data.tabungan.hash
      const inputPass = md5(pass)
      if (passUser === inputPass) {
        setIsCorrect(true);
        setLoading(false);
      } else {
        setIsCorrect(false);
        alert('Password Yang Anda Masukkan Salah')
        setLoading(false);
      }
    } catch (error) {
      alert(error);
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <div>
      {
        data.tabungan.hash === undefined
          ? <CreatePassword 
              setIsPass={setIsPass}
              data={data}
              loading={loading}
              setLoading={setLoading} />
          : !isPass
            ? <CreatePassword
                setIsPass={setIsPass}
                data={data}
                loading={loading}
                setLoading={setLoading} />
            : <>
                {
                  !isCorrect
                    ? <>
                        <TextField
                          id="pass"
                          label="Masukkan Password"
                          type="password"
                          value={pass}
                          onChange={(e) => setPass(e.target.value)}
                          fullWidth
                          autoFocus
                          margin="normal"
                        />
                        <Grid container spacing={4}>
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              fullWidth
                              color="primary"
                              onClick={handlePass}
                              disabled={loading}
                              startIcon={loading ? <CircularProgress/> : <LockIcon/>}
                            >
                              Konfirmasi
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              variant="contained"
                              fullWidth
                              color="primary"
                              onClick={() => setIsPass(false)}
                              disabled={loading}
                              startIcon={loading ? <CircularProgress/> : <LockIcon/>}
                            >
                              Reset Password
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    : <WithDrawScreen data={data} handleClose={handleClose}  />
                }
              </>
      }
    </div>
  );
}
