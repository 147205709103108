import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { firestore } from 'src/config/firebase';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { fareFormatter } from 'src/utils/fareFormatter';
import { Paper, Grid, Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AdminTable from './admin';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '25vw',
    backgroundColor: 'red'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ChartData = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [startAt, setStartAt] = useState(moment());
  const [sort, setSort] = useState("payment");
  const [inComePayment, setInComePayment] = useState("Memuat");
  const [outCome, setOutCome] = useState("Memuat");

  useEffect(() => {
    if (sort === 'payment') {
      getData();
    } else if (sort === 'tabungan') {
      getDataTabungan();
    } else if (sort === 'saku') {
      getDataSaku();
    }
  }, [startAt, sort])

  const handleChangeSort = async (e) => {
    setSort(e.target.value)
  }
  const getData = async () => {
    setInComePayment("Memuat")
    try {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        const getIncomePayment = (
          await firestore.collection('paymentHistory')
            .where('createAt', '>=', new Date(startAt.startOf('day').valueOf()))
            .where('createAt', '<=', new Date(startAt.endOf('day').valueOf()))
            .where("isManual", "==", true)
            .limit(10)
            .get()
        ).docs.map((e) => e.data().totalAmount)
        setInComePayment(getIncomePayment.reduce((a, b) => a + b, 0));
        setOutCome(0);
      } else {
        const getIncomePayment = (
          await firestore.collection('paymentHistory')
            .where('createAt', '>=', new Date(startAt.startOf('day').valueOf()))
            .where('createAt', '<=', new Date(startAt.endOf('day').valueOf()))
            .where("isManual", "==", true)
            .get()
          ).docs.map((e) => e.data().totalAmount)
        setInComePayment(getIncomePayment.reduce((a, b) => a + b, 0))
        setOutCome(0);
      }
    } catch (err) {
      console.log(err);
      alert(err)
    }
  }
  const getDataTabungan = async () => {
    setInComePayment("Memuat")
    try {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        const getIncomeTabungam = (
          await firestore.collection('tabunganHistory')
            .where('createAt', '>=', new Date(startAt.startOf('day').valueOf()))
            .where('createAt', '<=', new Date(startAt.endOf('day').valueOf()))
            .where("type", '==', 'Masuk')
            .limit(10)
            .get()
        ).docs.map((e) => e.data().totalAmount);
        const getOutComeTabungam = (
          await firestore.collection('tabunganHistory')
            .where('createAt', '>=', new Date(startAt.startOf('day').valueOf()))
            .where('createAt', '<=', new Date(startAt.endOf('day').valueOf()))
            .where("type", '==', 'Keluar')
            .limit(10)
            .get()
        ).docs.map((e) => e.data().totalAmount);
        setInComePayment(getIncomeTabungam.reduce((a, b) => a + b, 0));
        setOutCome(getOutComeTabungam.reduce((a, b) => a + b, 0))
      } else {
        const getIncomeTabungam = (
          await firestore.collection('tabunganHistory')
            .where('createAt', '>=', new Date(startAt.startOf('day').valueOf()))
            .where('createAt', '<=', new Date(startAt.endOf('day').valueOf()))
            .where("type", '==', 'Masuk')
            .get()
          ).docs.map((e) => e.data().totalAmount);
        const getOutComeTabungam = (
          await firestore.collection('tabunganHistory')
            .where('createAt', '>=', new Date(startAt.startOf('day').valueOf()))
            .where('createAt', '<=', new Date(startAt.endOf('day').valueOf()))
            .where("type", '==', 'Keluar')
            .get()
        ).docs.map((e) => e.data().totalAmount)
        setInComePayment(getIncomeTabungam.reduce((a, b) => a + b, 0));
        setOutCome(getOutComeTabungam.reduce((a, b) => a + b, 0))
      }
    } catch (err) {
      console.log(err);
      alert(err)
    }
  }
  const getDataSaku = async () => {
    setInComePayment("Memuat")
    try {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        const getIncomeTabungam = (
          await firestore.collection('sakuHistory')
            .where('createAt', '>=', new Date(startAt.startOf('day').valueOf()))
            .where('createAt', '<=', new Date(startAt.endOf('day').valueOf()))
            .where("type", '==', 'Masuk')
            .limit(10)
            .get()
        ).docs.map((e) => e.data().totalAmount);
        const getOutComeTabungam = (
          await firestore.collection('sakuHistory')
            .where('createAt', '>=', new Date(startAt.startOf('day').valueOf()))
            .where('createAt', '<=', new Date(startAt.endOf('day').valueOf()))
            .where("type", '==', 'Keluar')
            .limit(10)
            .get()
        ).docs.map((e) => e.data().totalAmount);
        setInComePayment(getIncomeTabungam.reduce((a, b) => a + b, 0));
        setOutCome(getOutComeTabungam.reduce((a, b) => a + b, 0))
      } else {
        const getIncomeTabungam = (
          await firestore.collection('sakuHistory')
            .where('createAt', '>=', new Date(startAt.startOf('day').valueOf()))
            .where('createAt', '<=', new Date(startAt.endOf('day').valueOf()))
            .where("type", '==', 'Masuk')
            .get()
          ).docs.map((e) => e.data().totalAmount);
        const getOutComeTabungam = (
          await firestore.collection('sakuHistory')
            .where('createAt', '>=', new Date(startAt.startOf('day').valueOf()))
            .where('createAt', '<=', new Date(startAt.endOf('day').valueOf()))
            .where("type", '==', 'Keluar')
            .get()
        ).docs.map((e) => e.data().totalAmount)
        setInComePayment(getIncomeTabungam.reduce((a, b) => a + b, 0));
        setOutCome(getOutComeTabungam.reduce((a, b) => a + b, 0))
      }
    } catch (err) {
      console.log(err);
      alert(err)
    }
  }
  const jsonData = {
    name: 'Sempak',
    id: 5
  }
    return (
      <Paper style={{padding: 25}}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} lg={4} md={4}>
            <Paper style={{padding: 25}}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  label="Mulai Tanggal"
                  format="DD/MM/YYYY"
                  value={startAt}
                  onChange={(date) => setStartAt(date)}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Kategori</InputLabel>
                <Select
                  value={sort}
                  onChange={handleChangeSort}
                >
                  <MenuItem value="payment">Payment</MenuItem>
                  <MenuItem value="tabungan">Tabungan</MenuItem>
                  <MenuItem value="saku">Uang Saku</MenuItem>
                </Select>
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <Paper style={{padding: 25, backgroundColor: '#32a852'}}>
              <div>
                <Typography variant="h4" style={{color: 'white'}}>
                  Uang Masuk
                </Typography>
              </div>
              <div>
                <Typography variant="h1" style={{color: 'white', fontWeight: 'bold'}}>
                  {inComePayment === "Memuat" ? "Memuat" : fareFormatter(inComePayment)}
                </Typography>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <Paper style={{padding: 25, backgroundColor: '#fc3a3a'}}>
              <div>
                <Typography variant="h4" style={{color: 'white'}}>
                  Uang Keluar
                </Typography>
              </div>
              <div>
                <Typography variant="h1" style={{color: 'white', fontWeight: 'bold'}}>
                  {outCome === "Memuat" ? "Memuat" : fareFormatter(outCome)}
                </Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <AdminTable startDate={startAt} />
      </Paper>
    )
  }


export default ChartData
