import React, { PureComponent } from 'react';
import moment from 'moment';

export default class Pages extends PureComponent {
  render() {
    console.log(this.props.data)
    const data = this.props.data
    return (
      <div>
        <font size="1" >
        <table border="1">    
          <thead>    
            <tr>
              <th style={{textAlign: 'center'}} scope="col" style={{width: "5%"}}>No</th>
              <th style={{textAlign: 'center'}} scope="col" style={{width: "10%"}}>Tanggal</th>
              <th style={{textAlign: 'center'}} scope="col" style={{width: "10%"}}>Teller</th>
              <th style={{textAlign: 'center'}} colSpan={2} scope="col" style={{width: "25%"}}>Mutasi</th>
              <th style={{textAlign: 'center'}} scope="col" style={{width: "10%"}}>Saldo</th>
              <th style={{textAlign: 'center'}} scope="col" style={{width: "10%"}}>Keterangan</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>    
              <td></td>
              <td style={{textAlign: 'center'}} scope="col">debet</td>
              <td style={{textAlign: 'center'}} scope="col">kredit</td>
              <td></td>    
              <td></td> 
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td style={{textAlign: 'center'}}>1</td>
              <td style={{textAlign: 'center'}}>{`${moment(data.createAt.toDate()).format("DD-MM-YYYY")}`}</td>    
              <td style={{textAlign: 'center'}}>{data.tellerData === undefined ? ' - ' : data.tellerData.id}</td>
              <td style={{textAlign: 'center'}}>{data.type === 'Keluar' ? '' : data.totalAmount}</td>
              <td style={{textAlign: 'center'}}>{data.type === 'Keluar' ? data.totalAmount : ''}</td>
              <td style={{textAlign: 'center'}}>{data.type === 'Keluar' ? data.userData.tabungan.value - data.totalAmount : data.userData.tabungan.value + data.totalAmount}</td>    
              <td style={{textAlign: 'center'}}>{data.keterangan}</td> 
            </tr>
          </tbody>
        </table>
        </font>
      </div>
    )
  }
}
