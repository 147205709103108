import React from 'react';
import { Document, Page, Text, View, PDFViewer, Image } from '@react-pdf/renderer';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { styles } from './style.print';
import { logo } from './logo';
import 'moment/locale/id';
import moment from 'moment';
import { fareFormatter } from '../../utils/fareFormatter';
import angkaTerbilang from '@develoka/angka-terbilang-js';

const MyDocTabungan = ({item}) => {
  
  return (
    <Document title="Kwitansi-pembayaran">
      <Page size="LETTER" orientation="portrait" style={styles.page}>
        <View style={{flexDirection: 'row', marginTop: 10, maxWidth: '85%', alignSelf: 'center'}}>
          <Image 
            src={{uri: logo}} 
            style={styles.logo} />
          <View style={styles.line} />
          <View>
            <Text style={styles.KopHeadBold}>
              BAITUL MAL AL-MUHIBBIN
            </Text>
            <Text style={styles.kopSubHead}>
              PONDOK PESANTREN BUMI DAMAI AL-MUHIBBIN BAHRUL ‘ULUM
            </Text>
            <Text style={styles.kopSubHead}>
              Jl. KH. Abd. Wahab Chasbulloh Gg. II No. 120A Tambakberas Jombang Jawa Timur
            </Text>
            <Text style={styles.kopSubHead}>
              Kode Pos : 61451 No. Telp : (0321) 865309
            </Text>
            <Text style={styles.kopSubHead}>
              Email : 313baitulmal@gmail.com WA : 0857 0672 8449
            </Text>
          </View>
        </View>
        <View style={{marginVertical: 5, maxWidth: '100%', height: 0.5, backgroundColor: 'black'}} />
        <View style={{marginTop: 10, maxWidth: '85%', alignSelf: 'center'}}>
          <Text style={{fontSize: 9, textAlign: 'center', fontWeight: 'bold'}}>
            BUKTI TRANSAKSI
          </Text>
          <Text style={{fontSize: 7, fontStyle: 'italic', marginLeft: 25, marginTop: 10, marginBottom: 5}}>
            Telah diterima dari :
          </Text>
          <View style={{ flexDirection: 'row', marginHorizontal: 25}}>
            <View style={{flexDirection: 'row', width: '70%'}}>
              <Text style={{fontSize: 7, marginRight: 5}}>
                No. Rekening
              </Text>
              <Text style={{fontSize: 7}}>
                {`: ${item.userData.noRekening.toUpperCase()}`}
              </Text>
            </View>
            <View style={{flexDirection: 'row', width: '25%'}}>
              <Text style={{fontSize: 7, marginRight: 19}}>
                No. Bukti 
              </Text>
              <Text style={{fontSize: 7}}>
                {`: ${item.id}`}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', marginHorizontal: 25}}>
            <View style={{flexDirection: 'row', width: '70%'}}>
              <Text style={{fontSize: 7, marginRight: 8}}>
                Nama Santri
              </Text>
              <Text style={{fontSize: 7}}>
                {`: ${item.userData.name.toUpperCase()}`}
              </Text>
            </View>
            <View style={{flexDirection: 'row', width: '25%'}}>
              <Text style={{fontSize: 7, marginRight: 5}}>
                Tgl. Transaksi
              </Text>
              <Text style={{fontSize: 7}}>
                {`: ${moment(item.createAt.toDate()).format('DD MMM YYYY')}`}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', marginHorizontal: 25}}>
            <View style={{flexDirection: 'row', width: '70%'}}>
              <Text style={{fontSize: 7, marginRight: 6}}>
                Kamar Santri
              </Text>
              <Text style={{fontSize: 7}}>
                {`: ${item.userData.kamar}`}
              </Text>
            </View>
            <View style={{flexDirection: 'row', width: '25%'}}>
              <Text style={{fontSize: 7, marginRight: 24}}>
                Metode
              </Text>
              <Text style={{fontSize: 7}}>
                {`: ${item.metode === undefined ? 'Transfer ' : item.metode.toUpperCase()}`}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', marginHorizontal: 25}}>
            <View style={{flexDirection: 'row', width: '70%'}}>
              <Text style={{fontSize: 7, marginRight: 3}}>
                Sekolah/Kelas
              </Text>
              <Text style={{fontSize: 7}}>
                {`: ${item.userData.sekolah}`}
              </Text>
            </View>
            <View style={{flexDirection: 'row', width: '25%'}}>
              <Text style={{fontSize: 7, marginRight: 8}}>
                Status Siswa
              </Text>
              <Text style={{fontSize: 7}}>
                {`: Aktif`}
              </Text>
            </View>
          </View>
        </View>
        <View style={{marginVertical: 3, maxWidth: '100%', height: 0.5, backgroundColor: 'black'}} />
        <View style={{maxWidth: '100%'}}>
          <View style={{marginVertical: 5}}>
            <Text style={{fontSize: 10, textAlign: 'center', marginBottom: 3}}>
              Transaksi: {`${item.type === 'Masuk' ? 'Pengisian' : 'Penarikan'}`} Saldo {`${item.isTabungan ? 'Tabungan' : 'Uang Saku'}`} 
            </Text>
            <Text style={{fontSize: 10, textAlign: 'center'}}>
              {`${fareFormatter(item.totalAmount)}`}
            </Text>
          </View>
        </View>
        <View style={{marginBottom: 3, maxWidth: '100%', height: 0.5, backgroundColor: 'black', marginTop: 5}} />
        <View style={{maxWidth: '85%', alignSelf: 'center'}}>
          <View style={{flexDirection: 'row'}}>
            <View style={{width: '25%'}}>
              <Text style={{fontSize: 7, textAlign: 'center'}}>
                Penyetor
              </Text>
              <Text style={{fontSize: 7, marginTop: 60, textAlign: 'center'}}>
                {`(                               )`}
              </Text>
            </View>
            <View style={{width: '25%', marginLeft: 10, marginRight: 10}}>
              <Text style={{fontSize: 7, textAlign: 'center'}}>
                Penerima
              </Text>
              <Text style={{fontSize: 7, marginTop: 60, textAlign: 'center'}}>
                {`( ${item.tellerData.name} )`}
              </Text>
            </View>
            <View style={{width: '40%', marginLeft: 15}}>
              <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <View>
                  <Text style={{fontSize: 8}}>
                    Total Pembayaran
                  </Text>
                </View>
                <View style={{width: '50%'}}>
                  <Text style={{fontSize: 8}}>
                    {`: ${fareFormatter(item.totalAmount)}`}
                  </Text>
                </View>
              </View>
              <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <View>
                  <Text style={{fontSize: 8}}>
                    Terbilang
                  </Text>
                </View>
                <View style={{width: '50%'}}>
                  <Text style={{fontSize: 8}} wrap>
                    {`: ${angkaTerbilang(item.totalAmount)}`}
                  </Text>
                </View>
              </View>
              <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <View>
                  <Text style={{fontSize: 8}}>
                    Dibayarkan
                  </Text>
                </View>
                <View style={{width: '50%'}}>
                  <Text style={{fontSize: 8}}>
                    {`: ${fareFormatter(item.uangMasuk === undefined ? item.totalAmount : item.uangMasuk)}`}
                  </Text>
                </View>
              </View>
              <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <View>
                  <Text style={{fontSize: 8}}>
                    Kembalian
                  </Text>
                </View>
                <View style={{width: '50%'}}>
                  <Text style={{fontSize: 8}}>
                    {`: ${fareFormatter('0')}`}
                  </Text>
                </View>
              </View>
              <View style={{marginBottom: 3, height: 0.5, backgroundColor: 'black', marginTop: 5}} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const MyDoc = ({item}) => {
  if (item.isTabungan || item.isSaku) {
    return (
      <MyDocTabungan item={item} />
    )
  }
  return (
    <Document title="Kwitansi-pembayaran">
      <Page size="LETTER" orientation="portrait" style={styles.page}>
        <View style={{flexDirection: 'row', marginTop: 10, maxWidth: '85%', alignSelf: 'center'}}>
          <Image 
            src={{uri: logo}} 
            style={styles.logo} />
          <View style={styles.line} />
          <View>
            <Text style={styles.KopHeadBold}>
              BAITUL MAL AL-MUHIBBIN
            </Text>
            <Text style={styles.kopSubHead}>
              PONDOK PESANTREN BUMI DAMAI AL-MUHIBBIN BAHRUL ‘ULUM
            </Text>
            <Text style={styles.kopSubHead}>
              Jl. KH. Abd. Wahab Chasbulloh Gg. II No. 120A Tambakberas Jombang Jawa Timur
            </Text>
            <Text style={styles.kopSubHead}>
              Kode Pos : 61451 No. Telp : (0321) 865309
            </Text>
            <Text style={styles.kopSubHead}>
              Email : 313baitulmal@gmail.com WA : 0857 0672 8449
            </Text>
          </View>
        </View>
        <View style={{marginVertical: 5, maxWidth: '100%', height: 0.5, backgroundColor: 'black'}} />
        <View style={{marginTop: 5, maxWidth: '85%', alignSelf: 'center'}}>
          <Text style={{fontSize: 9, textAlign: 'center', fontWeight: 'bold'}}>
            BUKTI TRANSAKSI PEMBAYARAN
          </Text>
          <Text style={{fontSize: 7, fontStyle: 'italic', marginLeft: 25, marginTop: 5, marginBottom: 5}}>
            Telah diterima dari :
          </Text>
          <View style={{ flexDirection: 'row', marginHorizontal: 25}}>
            <View style={{flexDirection: 'row', width: '70%'}}>
              <Text style={{fontSize: 7, marginRight: 5}}>
                No. Rekening
              </Text>
              <Text style={{fontSize: 7}}>
                {`: ${item.userData.noRekening.toUpperCase()}`}
              </Text>
            </View>
            <View style={{flexDirection: 'row', width: '25%'}}>
              <Text style={{fontSize: 7, marginRight: 19}}>
                No. Bukti 
              </Text>
              <Text style={{fontSize: 7}}>
                {`: ${item.id}`}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', marginHorizontal: 25}}>
            <View style={{flexDirection: 'row', width: '70%'}}>
              <Text style={{fontSize: 7, marginRight: 8}}>
                Nama Santri
              </Text>
              <Text style={{fontSize: 7}}>
                {`: ${item.userData.name.toUpperCase()}`}
              </Text>
            </View>
            <View style={{flexDirection: 'row', width: '25%'}}>
              <Text style={{fontSize: 7, marginRight: 5}}>
                Tgl. Transaksi
              </Text>
              <Text style={{fontSize: 7}}>
                {`: ${moment(item.createAt.toDate()).format('DD MMM YYYY')}`}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', marginHorizontal: 25}}>
            <View style={{flexDirection: 'row', width: '70%'}}>
              <Text style={{fontSize: 7, marginRight: 6}}>
                Kamar Santri
              </Text>
              <Text style={{fontSize: 7}}>
                {`: ${item.userData.kamar}`}
              </Text>
            </View>
            <View style={{flexDirection: 'row', width: '25%'}}>
              <Text style={{fontSize: 7, marginRight: 24}}>
                Metode
              </Text>
              <Text style={{fontSize: 7}}>
                {`: ${item.metode === undefined ? ' ATM ' : item.metode.toUpperCase()}`}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', marginHorizontal: 25}}>
            <View style={{flexDirection: 'row', width: '70%'}}>
              <Text style={{fontSize: 7, marginRight: 3}}>
                Sekolah/Kelas
              </Text>
              <Text style={{fontSize: 7}}>
                {`: ${item.userData.sekolah}`}
              </Text>
            </View>
            <View style={{flexDirection: 'row', width: '25%'}}>
              <Text style={{fontSize: 7, marginRight: 8}}>
                Status Siswa
              </Text>
              <Text style={{fontSize: 7}}>
                {`: Aktif`}
              </Text>
            </View>
          </View>
        </View>
        <View style={{marginVertical: 3, maxWidth: '100%', height: 0.5, backgroundColor: 'black'}} />
        <Text style={{fontSize: 7, fontStyle: 'italic', marginLeft: 100}}>
          Dengan rincian sebagai berikut :
        </Text>
        <View style={{marginVertical: 3, maxWidth: '100%', height: 0.5, backgroundColor: 'black'}} />
        <View style={{maxWidth: '60%', marginLeft: 40, alignSelf: 'center'}}>
          {
            item.paymentDataId === undefined
              ? null
              : item.paymentDataId.map((a, b) => {
                  return (
                    <View key={b} style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                      <View style={{width: '60%'}}>
                        <Text style={{fontSize: 7, textTransform: 'capitalize'}}>
                          {`${b+1}. ${a.name}`}
                        </Text>
                      </View>
                      <View style={{width: '60%'}}>
                        <Text style={{fontSize: 7}}>
                          {`${fareFormatter(a.amount)}`}
                        </Text>
                      </View>
                    </View>
                  )
                })
          }
        </View>
        <View style={{marginBottom: 3, maxWidth: '100%', height: 0.5, backgroundColor: 'black', marginTop: 5}} />
        <View style={{maxWidth: '85%', alignSelf: 'center'}}>
          <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
            <View style={{width: '25%'}}>
              <Text style={{fontSize: 7, textAlign: 'center'}}>
                Penyetor
              </Text>
              <Text style={{fontSize: 7, marginTop: 75, textAlign: 'center'}}>
                {`(                               )`}
              </Text>
            </View>
            <View style={{width: '25%', marginLeft: 10, marginRight: 10}}>
              <Text style={{fontSize: 7, textAlign: 'center'}}>
                Penerima
              </Text>
              <Text style={{fontSize: 7, marginTop: 75, textAlign: 'center'}}>
                {`( ${item.tellerData.name} )`}
              </Text>
            </View>
            <View style={{width: '40%', marginLeft: 15}}>
              <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <View>
                  <Text style={{fontSize: 8}}>
                    Total Pembayaran
                  </Text>
                </View>
                <View style={{width: '50%'}}>
                  <Text style={{fontSize: 8}}>
                    {`: ${fareFormatter(item.totalAmount)}`}
                  </Text>
                </View>
              </View>
              <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <View>
                  <Text style={{fontSize: 8}}>
                    Terbilang
                  </Text>
                </View>
                <View style={{width: '50%'}}>
                  <Text style={{fontSize: 8}} wrap>
                    {`: ${angkaTerbilang(item.totalAmount)}`}
                  </Text>
                </View>
              </View>
              <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <View>
                  <Text style={{fontSize: 8}}>
                    Dibayarkan
                  </Text>
                </View>
                <View style={{width: '50%'}}>
                  <Text style={{fontSize: 8}}>
                    {`: ${fareFormatter(item.uangMasuk === undefined ? item.totalAmount : item.uangMasuk)}`}
                  </Text>
                </View>
              </View>
              <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <View>
                  <Text style={{fontSize: 8}}>
                    Kembalian
                  </Text>
                </View>
                <View style={{width: '50%'}}>
                  <Text style={{fontSize: 8}}>
                    {`: ${fareFormatter(item.uangMasuk === undefined ? '0' : item.uangMasuk - item.totalAmount)}`}
                  </Text>
                </View>
              </View>
              <View style={{marginBottom: 3, height: 0.5, backgroundColor: 'black', marginTop: 5}} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const Print = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Button 
        onClick={handleClickOpen} 
        variant="contained"
        color="primary" 
        style={{marginTop: 10}}>
        Kwitansi
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <PDFViewer style={{width: '90vw', height: '90vh'}}>
            <MyDoc item={data} />
          </PDFViewer>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary">
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Print;
