import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { firestore } from 'src/config/firebase';
import { green } from '@material-ui/core/colors';
import 'moment/locale/id';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { fareFormatter } from 'src/utils/fareFormatter';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(5),
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

function FormDialog({ payData }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(false);
  const [name, setName] = React.useState('');
  const [nominal, setNominal] = React.useState(0);
  const [itemValue, setItemValue] = React.useState(0);
  const [multiValue, setMultiValue] = React.useState([]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
    setName('');
    setNominal(0);
    setItemValue(0);
    setMultiValue([]);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSaveItem = async () => {
    const ref = await firestore.collection('payment').doc(`${payData.id}`)
                  .collection('list-payment');
    const setData = {
      createAt: new Date(),
      isPaid: false,
      multiple: value,
      name: name,
      value: value ? multiValue : parseInt(nominal)
    };
    try {
      await ref.add(setData);
      alert('Data Berhasil Tersimpan');
      handleClose();
    } catch (error) {
      console.log(error);
      alert('Terjadi Kesalahan Internal');
    }
  };
  const handleAddItemValue = (e) => {
    setMultiValue(multiValue.concat(parseInt(itemValue)));
  };
  const handleDelete = (item) => {
    setMultiValue(multiValue.filter((f) => f !== item));
  };
  return (
    <div>
      {/* <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Tambah Pembayaran
      </Button> */}
      <Fab variant="extended" className={classes.fab} onClick={handleClickOpen}>
        <PostAddIcon className={classes.extendedIcon} />
        Pembayaran
      </Fab>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">Tambah Pembayaran</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nama"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <FormControl className={classes.formControl} margin="normal" fullWidth>
            <InputLabel id="demo-simple-select-label">Kategori</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              onChange={handleChange}
            >
              <MenuItem value={false}>Single Value</MenuItem>
              <MenuItem value={true}>Multiple value</MenuItem>
            </Select>
          </FormControl>
          {
            value
              ? <div>
                  <Grid container spacing={1}>
                    <Grid item xs={10}>
                      <TextField
                        label="Masukkan Nominal"
                        value={itemValue}
                        onChange={(e) => setItemValue(e.target.value)}
                        type="number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton color="primary" aria-label="upload picture" onClick={handleAddItemValue}>
                        <CheckIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
              : <TextField
                  autoFocus
                  margin="dense"
                  id="date"
                  label="Nominal"
                  fullWidth
                  value={nominal}
                  onChange={(e) => setNominal(e.target.value)}
                  type="number"
                />
          }
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {
              multiValue.map((item, idx) => {
                return (
                  <div key={idx} style={{ margin: 2 }}>
                    <Chip label={`${fareFormatter(item)}`} variant="outlined" onDelete={() => handleDelete(item)}/>
                  </div>
                );
              })
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Batal
          </Button>
          <Button onClick={handleSaveItem} color="primary">
            Tambah
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialog;
