import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Zoom from '@material-ui/core/Zoom';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Fab from '@material-ui/core/Fab';
import { firestore } from 'src/config/firebase';
import { LinearProgress, Badge } from '@material-ui/core';
import { useSelector } from 'react-redux';

import PaymentList from './PaymentList';
import CartDialog from './cart';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ dataSantri, open, handleClose }) {
  const classes = useStyles();
  const theme = useTheme();
  const cart = useSelector(state => state.cart.cart);
  const [openCart, setOpenCart] = useState(false);
  const [paymentData, setPaymentData] = useState(null);

  const getData = async () => {
    try {
      await firestore.collection('santri').doc(`${dataSantri.noRekening}`)
        .collection('payment').orderBy('createAt', 'desc')
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((item) => {
                          let a;
                          a = item.data();
                          a.id = item.id;
                          return a;
                        });
          setPaymentData(data)
        })
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        keepMounted={false}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {`Pembayaran ${dataSantri.name}`}
            </Typography>
          </Toolbar>
        </AppBar>
        {
          paymentData === null
            ? <LinearProgress color="primary" />
            : <List>
                <PaymentList
                  data={paymentData === undefined ? [] : paymentData}
                  dataSantri={dataSantri}
                />
              </List>
        }
        <CartDialog dataSantri={dataSantri} open={openCart} handleClose={() => setOpenCart(false)} />
        <Zoom
          in
          timeout={transitionDuration}
          style={{
            transitionDelay: `10ms`,
          }}
          unmountOnExit
        >
          <Fab onClick={() => setOpenCart(true)} aria-label="Chart" className={classes.fab} color="primary">
            <Badge badgeContent={cart.length} color="secondary">
              <ShoppingCartIcon />
            </Badge>
          </Fab>
        </Zoom>
      </Dialog>
    </div>
  );
}
