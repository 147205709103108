import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { firestore } from 'src/config/firebase';

const paymentCollection = 'paymentHistory';

export default function FormDialog({ row }) {
  const dataSantri = row.userData;
  const teller = useSelector(state => state.user.profile);
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleRejectPayment = async () => {
    try {
      setLoading(true);
      const batch = firestore.batch();
      const santriRef = firestore.collection('santri').doc(`${dataSantri.noRekening}`)
      const paymentHistoryRef = firestore.collection(paymentCollection).doc(`${row.id}`)
      const santriHistoryRef = santriRef.collection(paymentCollection).doc(`${row.id}`)
      batch.delete(paymentHistoryRef);
      batch.delete(santriHistoryRef);
      await batch.commit();
      setLoading(false);
      handleClose()
    } catch (err) {
      console.log(err);
      setLoading(true);
    }
  } 

  const handleClose = async () => {
    try {
      setOpen(false)
    } catch (err) {
      console.log(err)
    }
  }

  return ( 
    <div>
      <IconButton aria-label="delete" onClick={() => setOpen(true)}>
        <DeleteOutlineIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Konfirmasi Tindakan</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { `Apakah Anda Yakin Untuk Menghapus Pembayaran ${row.userData.name}` }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            variant='outlined' 
            onClick={handleClose} 
            color="secondary"
            disabled={loading}
          >
            Batal
          </Button>
          <Button 
            onClick={handleRejectPayment} 
            variant='contained' 
            color="primary"
            disabled={loading}
          >
            { loading ? 'Menghapus...' : 'Konfirmasi' }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
