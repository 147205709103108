import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { firestore } from 'src/config/firebase';

import ListPayment from './listPayment';
import { CircularProgress } from '@material-ui/core';

export default function AlertDialog({ dataSantri, paymentData }) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState(null);

  const getDataSantri = async () => {
    try {
      const listPaymentData = (
        await firestore.collection('santri').doc(`${dataSantri.noRekening}`)
          .collection('payment').doc(`${paymentData.id}`)
          .collection('list-payment').where('isPaid', '==', false).get()
      ).docs.map((item) => item.data())
      setData(listPaymentData)
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }

  const handleClickOpen = () => {
    getDataSantri()
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Rincian Tunggakan
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {data === null
            ? <CircularProgress />
            : <ListPayment data={data} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
