import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { storage, firestore } from 'src/config/firebase';
import { uuid } from 'src/config/uuid';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 200,
  },
  input: {
    display: 'none',
  },
});

const imagePlaceholder = 'https://thumbs.dreamstime.com/b/no-thumbnail-image-placeholder-forums-blogs-websites-148010362.jpg'

export default function FormDialog({ open, handleClose }) {
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);

  const handleChangeImage = async (e) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        console.log('cancel')
      } else {
        var file = e.target.files[0]
        var reader = new FileReader()
        var url = reader.readAsDataURL(file); 
        reader.onloadend = () => {
          console.log(reader.result)
          setImage(reader.result)
        }
        // setImage(url)
        console.log(e.target.files[0])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const saveFile = async (id) => {
    try {
      if (image !== null) {
        var storageRef = storage.ref().child(`pengumuman/${id}.jpg`)
        const uploadRes = await storageRef.putString(image, 'data_url')
        const downloadUrl = await uploadRes.ref.getDownloadURL()
        console.log(downloadUrl)
        return downloadUrl
      } else {
        console.log("Image = null")
        return null
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleSave = async () => {
    try {
      const id = uuid(6)
      const ref = firestore.collection('pengumuman').doc(`${id}`)
      const uplodaImage = await saveFile(id)
      const setData = {
        createAt: new Date(),
        title: title,
        content: content,
        image: uplodaImage,
        id: id
      }
      await ref.set(setData, { merge: true })
      handleClose()
    } catch (err) {
      console.log(err);
      alert(`${err}`)
    }
  }

  return (
    <div>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        aria-labelledby="form-dialog-title"
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>Tambah Pengumuman</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Judul"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Konten"
            fullWidth
            variant='outlined'
            multiline
            maxRows={5}
            minRows={5}
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={image === null ? imagePlaceholder : image}
                title="Contemplative Reptile"
              />
            </CardActionArea>
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                Bisa Dikosongkan
              </Typography>
            </CardContent>
            <CardActions>
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                type="file"
                onChange={(e) => handleChangeImage(e)}
              />
              <label htmlFor="contained-button-file">
                <Button size="small" color="primary" component="span">
                  Ambil Gambar
                </Button>
              </label>
              <Button size="small" color="secondary" onClick={() => setImage(null)}>
                Hapus
              </Button>
            </CardActions>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose} color="secondary">
            Batal
          </Button>
          <Button variant='contained' onClick={handleSave} color="primary">
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
