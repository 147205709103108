import { firestore } from "src/config/firebaseV1";

export const check = async (docId) => {
  try {
    const checking = (
      await firestore.collection('paymentHistory').doc(`${docId}`).get()
    ).exists
    return {
      code: '204',
      value: checking
    }
  } catch (err) {
    return {
      code: '200',
      value: 'error'
    }
  }
}