import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  makeStyles,
  Backdrop,
  CircularProgress
} from '@material-ui/core';
import { firestore } from 'src/config/firebase';
import md5 from 'md5';

const useStyles = makeStyles((theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Password = ({ className, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    password: '',
    name: '',
    id: ''
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSave = async () => {
    setLoading(true);
    const batch = firestore.batch()
    const ref = firestore.collection('admin').doc(`${values.id}`)
    const adminData = {
      name: values.name,
      id: values.id,
      createAt: new Date(),
      authority: {
        payment: false,
        saku: false,
        santriData: false,
        tabungan: false
      },
    }
    const hashData = {
      password: md5(values.password),
      updateAt: new Date()
    }
    const checkId = await ref.get()
    try {
      if (checkId.exists) {
        alert('ID Telah Digunakan !');
        setLoading(false);
      } else {
        batch.set(ref, adminData);
        batch.set(ref.collection('private').doc('hash'), hashData);
        batch.commit();
        setValues({
          password: '',
          name: '',
          id: ''
        })
        setLoading(false);
      }
    } catch (error) {
      alert(error);
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <Divider />
        <CardContent>
          <TextField
            label="Nama"
            fullWidth
            margin="normal"
            name="name"
            onChange={handleChange}
            value={values.name}
            variant="outlined"
          />
          <TextField
            label="ID"
            fullWidth
            margin="normal"
            name="id"
            onChange={handleChange}
            value={values.id}
            variant="outlined"
          />
          <TextField
            label="Password"
            fullWidth
            margin="normal"
            name="password"
            onChange={handleChange}
            value={values.password}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
          >
            Simpan
          </Button>
        </Box>
      </Card>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
