import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { fareFormatter } from 'src/utils/fareFormatter';
import { firestore } from 'src/config/firebaseV1';
import 'moment/locale/id';
import moment from 'moment';

const Income = ({ adminData, startDate }) => {
  const [TotalAmount, setTotalAmount] = useState("Memuat");
  // const [startDate, setStartDate] = useState(moment().startOf('day'));

  useEffect(() => {
    getTotalIncome()
  }, [startDate])

  const getTotalIncome = async () => {
    try {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        //! Uang Saku & Tabungan
        const value = (
          await firestore.collection('paymentHistory')
            .where('tellerData.id', '==', adminData.id)
            .where('status', '==', 'done')
            .where('type', '==', 'Masuk')
            .where('createAt', '>=', new Date(startDate.startOf('day').valueOf()))
            .where('createAt', '<=', new Date(startDate.endOf('day').valueOf()))
            .limit(5)
            .get()
        ).docs.map((e) => e.data().totalAmount)
        ///! Pembayaran
        const valuePayment = (
          await firestore.collection('paymentHistory')
            .where('tellerData.id', '==', adminData.id)
            .where('status', '==', 'done')
            .where('isManual', '==', true)
            .where('createAt', '>=', new Date(startDate.startOf('day').valueOf()))
            .where('createAt', '<=', new Date(startDate.endOf('day').valueOf()))
            .limit(5)
            .get()
        ).docs.map((e) => e.data().totalAmount)
        //! Uang Saku & Tabungan
        const sumValue = value.reduce((a, b) => a + b, 0);
        ///! Pembayaran
        const sumValuePayment = valuePayment.reduce((a, b) => a + b, 0);
        setTotalAmount(sumValue + sumValuePayment);
      } else {
        //! Uang Saku & Tabungan
        const value = (
          await firestore.collection('paymentHistory')
            .where('tellerData.id', '==', adminData.id)
            .where('status', '==', 'done')
            .where('type', '==', 'Masuk')
            .where('createAt', '>=', new Date(startDate.startOf('day').valueOf()))
            .where('createAt', '<=', new Date(startDate.endOf('day').valueOf()))
            .get()
        ).docs.map((e) => e.data().totalAmount)
        ///! Pembayaran
        const valuePayment = (
          await firestore.collection('paymentHistory')
            .where('tellerData.id', '==', adminData.id)
            .where('status', '==', 'done')
            .where('isManual', '==', true)
            .where('createAt', '>=', new Date(startDate.startOf('day').valueOf()))
            .where('createAt', '<=', new Date(startDate.endOf('day').valueOf()))
            .get()
        ).docs.map((e) => e.data().totalAmount)
        //! Uang Saku & Tabungan
        const sumValue = value.reduce((a, b) => a + b, 0);
        ///! Pembayaran
        const sumValuePayment = valuePayment.reduce((a, b) => a + b, 0);
        setTotalAmount(sumValue + sumValuePayment);
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }

  return (
    <Typography>
      {TotalAmount === 'Memuat' ? 'Memuat' : fareFormatter(TotalAmount)}
    </Typography>
  )
}

export default Income
