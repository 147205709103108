import React from 'react'
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { firestore } from 'src/config/firebase';
import { CircularProgress } from '@material-ui/core';
import { fareFormatter } from 'src/utils/fareFormatter';
import 'moment/locale/id';
import moment from 'moment';

const ValueColumn = ({ value }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
      {
        value.map((item, idx) => {
          return (<div key={idx}>{`${fareFormatter(item)}; `}</div>);
        })
      }
    </div>
  );
};

const ListPayment = ({ data }) => {

  const columns = [
    {
      name: 'createAt',
      title: 'Dibuat Pada',
      getCellValue: (row) => moment(row.createAt.toDate()).format('DD MMM YYYY')
    },
    {
      name: 'name',
      title: 'Nama',
      getCellValue: (row) => row.name.toUpperCase()
    },
    {
      name: 'value',
      title: 'Nominal',
      getCellValue: (row) => !row.multiple
                                ? fareFormatter(row.value)
                                : <ValueColumn value={row.value} />
    },
  ];
  if (data === null) {
    return (
      <CircularProgress />
    )
  }

  return (
    <Paper style={{padding: 10}}>
      <Grid
        rows={data}
        columns={columns}
      >
        <VirtualTable />
        <TableHeaderRow />
      </Grid>
    </Paper>
  )
}

export default ListPayment
