import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { firestore } from 'src/config/firebase';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function FormDialog({ open, handleClose, data }) {
  const classes = useStyles();
  const [name, setName] = useState(data.name)
  const [state, setState] = React.useState({
    tabungan: data.authority === undefined ? false : data.authority.tabungan,
    saku: data.authority === undefined ? false : data.authority.saku,
    payment: data.authority === undefined ? false : data.authority.payment,
    santriData: data.authority === undefined ? false : data.authority.santriData,
    laporan: data.authority === undefined ? false : data.authority.laporan,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleSave = async() => {
    setLoading(true);
    try {
      await firestore.collection('admin').doc(`${data.id}`)
        .set({
          authority: state,
          name: name,
          updateAt: new Date()
        }, { merge: true })
      alert('Sukses');
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert(error);
      setLoading(false);
    }
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth>
        <DialogTitle id="form-dialog-title">Edit Data</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nama"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Otoritas Layanan Admin</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={state.tabungan} onChange={handleChange} name="tabungan" />}
                label="Tabungan"
              />
              <FormControlLabel
                control={<Switch checked={state.saku} onChange={handleChange} name="saku" />}
                label="Uang Saku"
              />
              <FormControlLabel
                control={<Switch checked={state.payment} onChange={handleChange} name="payment" />}
                label="Pembayaran"
              />
              <FormControlLabel
                control={<Switch checked={state.santriData} onChange={handleChange} name="santriData" />}
                label="Data Santri"
              />
              <FormControlLabel
                control={<Switch checked={state.laporan} onChange={handleChange} name="laporan" />}
                label="Laporan"
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Tutup
          </Button>
          <Button variant="contained" onClick={handleSave} color="primary">
            Simpan
          </Button>
        </DialogActions>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </div>
  );
}
