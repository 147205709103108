import React from 'react';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { firestore } from 'src/config/firebase';

export default function AlertDialog({ dataSantri }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = async () => {
    try {
      await firestore.collection('santri').doc(`${dataSantri.noRekening}`)
        .collection('private').doc('hash').delete()
      handleClose()
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
        <ListItem button style={{height: 25}} onClick={handleClickOpen}>
          <ListItemIcon>
            <VpnKeyIcon />
          </ListItemIcon>
          <ListItemText primary="Reset Sandi" />
        </ListItem>
        <Dialog
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>{`Reset Password ${dataSantri.name}`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Apakah Anda Yakin Untuk Mereset Sandi ${dataSantri.name}? `}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='outlined' color="secondary">
              Batal
            </Button>
            <Button onClick={handleReset} variant='contained' color="primary" >
              Konfirmasi
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}
