import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { TextField } from '@material-ui/core';
import { fareFormatter } from 'src/utils/fareFormatter';
import moment from 'moment';

function ScrollDialog({ open, handleClose, updateData, itemData }) {
  const [scroll] = React.useState('paper');

  if (updateData === null) {
    return (
      <div />
    )
  }

  return (
    <div>
      <Dialog
        maxWidth="xs"
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogContent dividers={scroll === 'paper'}>
          <TextField 
            margin="dense"
            fullWidth 
            id="standard-basic" 
            label="Nominal Lama" 
            value={fareFormatter(updateData.previewsData.value)}
            contentEditable={false} />
          <TextField 
            margin="dense"
            fullWidth 
            id="standard-basic" 
            label="Nominal Baru" 
            value={fareFormatter(itemData.value)}
            contentEditable={false} />
          <TextField 
            margin="dense"
            fullWidth 
            id="standard-basic" 
            label="Petugas" 
            value={updateData.tellerData.name}
            contentEditable={false} />
          <TextField 
            margin="dense"
            fullWidth 
            id="standard-basic" 
            label="Tanggal Update" 
            value={moment(updateData.createAt.toDate()).format('DD-MM-YYYY, HH:mm:ss')}
            contentEditable={false} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="primary">
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ScrollDialog