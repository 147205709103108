import React from 'react'
import { fareFormatter } from 'src/utils/fareFormatter';

const Index = ({ row }) => {
  const filterArr = !row 
                      ? null
                      : row.filter((e) => e.tabungan !== undefined)
  const total = !filterArr 
                  ? 0
                  : filterArr.map((e) => e.tabungan.value).reduce((a, b) => a + b, 0)
  return (
    <div style={{fontWeight: 'bold'}}>{`Total Tabungan: ${fareFormatter(total)}`}</div>
  )
}

export default Index;
