import { fareFormatter } from 'src/utils/fareFormatter';
import DialogWithdraw from './components/withdraw/Dialog';
import AddSaldoDialog from './components/addSaldo/Dialog';
import HistoryTable from './components/history';


export const columns = [
  {
    name: 'nameSantri',
    title: 'Nama Santri',
    getCellValue: (row) => `${row.name === undefined ? ' - ' : row.name}`
  },
  {
    name: 'kamar',
    title: 'Kamar Santri',
    getCellValue: (row) => `${row.kamar === undefined ? ' - ' : row.kamar}`
  },
  {
    name: 'noRekening',
    title: 'Rekening Santri',
    getCellValue: (row) => row.noRekening
  },
  {
    name: 'ime',
    title: 'Nomor IME',
    getCellValue: (row) => row.ime === undefined ? ' - ' : row.ime
  },
  {
    name: 'value',
    title: 'Uang Saku',
    getCellValue: (row) => row.saku === undefined ? '0' : fareFormatter(row.saku.value)
  },
  {
    name: 'tambah',
    title: 'Tambah Saldo',
    getCellValue: (row) => <AddSaldoDialog data={row} />
  },
  {
    name: 'withdraw',
    title: 'Withdraw',
    getCellValue: (row) => <DialogWithdraw data={row} />
  },
  {
    name: 'history',
    title: 'Riwayat',
    getCellValue: (row) => <HistoryTable santriData={row} />
  }
];