import React, { useState, useEffect } from 'react';
import { TextField, Grid, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { firestore } from 'src/config/firebase';
import { makeid } from 'src/utils/id';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector } from 'react-redux';
import { fareFormatter } from 'src/utils/fareFormatter';
import { check } from 'src/utils/checkPayment';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


function Dettail({ data, handleClose }) {
  const classes = useStyles();
  const user = useSelector(state => state.user.profile);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('');
  const [method, setMethod] = useState(' - ');

  const isNumeric = (value) => {
    const reg = /^-?\d+$/.test(value);
    const cpl = value.match(/\d+/gi) === null ? '0' : parseInt(value.match(/\d+/gi).join(''));
    return {
      reg: reg,
      cpl: cpl
    }
  }

  const checkCondition = data.saku.value - isNumeric(value).cpl > 0 ? true : false;

  const handleWithdraw = async () => {
    setIsLoading(true);
    const sakuId = makeid(12);
    const withdraw = isNumeric(value).cpl;
    const batch = firestore.batch();
    const santriRef = firestore.collection('santri').doc(`${data.noRekening}`);
    const sakuHistory = santriRef.collection('sakuHistory').doc(`${sakuId}`);
    const sakuRef = firestore.collection('sakuHistory').doc(`${sakuId}`);
    const paymentHistoryRef = firestore.collection('paymentHistory').doc(`${sakuId}`);
    const sakuData = {
      createAt: new Date(),
      userData: data,
      isSaku: true,
      type: 'Keluar',
      totalAmount: withdraw,
      name: 'Uang Saku',
      status: 'done',
      id: sakuId,
      updateAt: new Date(),
      tellerData: user,
      method: method
    };
    const updateData = {
      saku: {
        value: data.saku.value - withdraw,
        updateAt: new Date()
      }
    };
    try {
      if (isNumeric(value).reg) {
        if (checkCondition) {
          if (Number.isNaN(withdraw)) {
            alert('Input Tidak Valid, Input Nominal Harus Berupa Angka saja dan Tidak Mencantumkan Titik, Koma, Atau Tanda Baca Lain');
            setIsLoading(false);
          } else {
            batch.set(sakuHistory, sakuData, { merge: true });
            batch.set(sakuRef, sakuData, { merge: true });
            batch.set(paymentHistoryRef, sakuData, { merge: true });
            batch.update(santriRef, updateData);
            await batch.commit();
            const isSuccees = await check(sakuId)
            if (isSuccees.value === true) {
              alert('Sukses');
              handleClose();
              setIsLoading(false);
            } else if (isSuccees.value === false) {
              alert('Transaksi Gagal');
              setIsLoading(false);
            } else {
              alert('Transaksi Gagal');
              setIsLoading(false);
            }
          }
        } else {
          alert('Persyaratan Saldo Untuk Penarikan Tidak Mencukupi');
          setIsLoading(false);
        }
      } else if (isNumeric(value).cpl === '0') {
        alert('Input Tidak Valid, Input Nominal Harus Berupa Angka saja dan Tidak Mencantumkan Titik, Koma, Atau Tanda Baca Lain');
        setIsLoading(false);
      } else if (!isNumeric(value).reg) {
        alert('Input Tidak Valid, Input Nominal Harus Berupa Angka saja dan Tidak Mencantumkan Titik, Koma, Atau Tanda Baca Lain');
        setIsLoading(false);
      }
    } catch (error) {
      alert(error);
      console.log(error);
      setIsLoading(false);
    }
  };

  if (!data) {
    return (
      <CircularProgress color="inherit" />
    )
  }

  return (
    <div>
      <TextField
        id="name"
        label="Nama Santri"
        fullWidth
        value={data.name}
        contentEditable={false}
        margin="normal"
      />
      <TextField
        id="nomor"
        label="Nomor Rekening Santri"
        fullWidth
        value={data.noRekening}
        contentEditable={false}
        margin="normal"
      />
      <TextField
        id="value"
        label="Saldo Uang Saku"
        fullWidth
        value={data.saku.value}
        contentEditable={false}
        margin="normal"
      />
      <TextField
        autoFocus
        margin="normal"
        id="id"
        label="Masukkan Nominal Penarikan"
        fullWidth
        value={value}
        type="number"
        onChange={(e) => setValue(e.target.value)}
        helperText={`Terbilang ${fareFormatter(value)}`}
      />
      <FormControl className={classes.formControl} fullWidth margin='normal'>
        <InputLabel>Metode Penarikan</InputLabel>
        <Select
          value={method}
          onChange={(e) => setMethod(e.target.value)}
          label="Metode Penarikan"
        >
          <MenuItem value={' - '}>
            <em>Pilih Metode Penarikan</em>
          </MenuItem>
          <MenuItem value='Transfer'>Transfer</MenuItem>
          <MenuItem value='Tunai'>Tunai</MenuItem>
          <MenuItem value='Lebihan Pembayaran'>Lebihan Pembayaran</MenuItem>
          <MenuItem value='Ke Tabungan'>Ke Tabungan</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleWithdraw}
        disabled={isLoading}
        fullWidth
      >
        Konfirmasi
      </Button>
    </div>
  );
}

export default Dettail;
