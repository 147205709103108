import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { fareFormatter } from 'src/utils/fareFormatter';
import {
  Typography,
  Grid,
  Button,
  CardHeader
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import 'moment/locale/id';
import moment from 'moment';

import RejectDialog from './rejectDialog';
import ConfirmDialog from './confirmDialog';

const RincianPembayaran = ({ data, ...props }) => {
  // console.log(data);
  return (
      <TextField
        label={data === null ? 'Memuat' : data.name.toUpperCase()}
        value={data === null ? 'Memuat' : fareFormatter(data.amount)}
        contentEditable={false}
        fullWidth
        margin="dense"
      />
  );
};

const useStyles = makeStyles(() => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  }
}));

function RowDettail({ row }) {
  const classes = useStyles();
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  return (
    <div>
      <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold' }}>
        Detail Pembayaran
      </Typography>
      <Grid container justifyContent="space-around" spacing={3}>
        <Grid item xs={6}>
          {
            row.paymentDataId.map((item) => {
              return (
                <RincianPembayaran key={item.id} data={item} />
              );
            })
          }
          {
            row.saku === null || row.saku === undefined
              ? null
              : <RincianPembayaran data={{name: row.saku.name, amount: row.saku.totalAmount}} />
          }
          {
            row.tabungan === null || row.tabungan === undefined
              ? null
              : <RincianPembayaran data={{name: row.tabungan.name, amount: row.tabungan.totalAmount}} />
          }
          <TextField
            label="Total Pembayaran"
            value={fareFormatter(row.totalAmount)}
            contentEditable={false}
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          {
            row.bill === undefined || row.bill === null
              ? <div />
              : <Card style={{ maxWidth: 345 }}>
                  <CardHeader
                    title="Bukti Pembayaran"
                    subheader={moment(row.confirmAt.toDate()).format('DD MMM YYYY')}
                  />
                  <CardMedia
                    className={classes.media}
                    image={row.bill}
                    title={row.billId}
                  />
                  <CardActions disableSpacing>
                    <Button href={row.bill} target="_blank">
                      Lihat
                    </Button>
                  </CardActions>
                </Card>
          }
          <Button
            variant='contained'
            color='primary'
            style={{margin: 10}}
            onClick={() => setOpenConfirmDialog(true)}
          >
            Konfirmasi Pembayaran
          </Button>
          <Button
            variant='outlined'
            color='secondary'
            style={{margin: 10}}
            onClick={() => setOpenRejectDialog(true)}
          >
            Tolak Pembayaran
          </Button>
        </Grid>
      </Grid>
      <RejectDialog 
        open={openRejectDialog}
        handleClose={() => setOpenRejectDialog(false)}
        row={row}
      />
      <ConfirmDialog 
        open={openConfirmDialog}
        handleClose={() => setOpenConfirmDialog(false)}
        row={row}
      />
    </div>
  )
}

export default RowDettail;
