/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { firestore } from 'src/config/firebase';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CheckboxLabels({ paymentData, dataSantri, data }) {
  const [state, setState] = React.useState(false);

  const checkPaymentStatus = async () => {
    const santriRef = firestore.collection('santri').doc(`${dataSantri.noRekening}`);
    const paymentSantriRef = await santriRef.collection('payment').doc(`${data.id}`)
                              .collection('list-payment').doc(`${paymentData.id}`).get();
    try {
      if (paymentSantriRef.exists) {
        setState(true);
      } else {
        setState(false);
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  React.useEffect(() => {
    checkPaymentStatus();
  }, []);

  const addPayment = async () => {
    const batch = firestore.batch();
    const santriRef = firestore.collection('santri').doc(`${dataSantri.noRekening}`);
    const paymentSantriRef = santriRef.collection('payment').doc(`${data.id}`);
    const paymentListSantriRef = paymentSantriRef.collection('list-payment').doc(`${paymentData.id}`);
    const setPaymentData = paymentData;
    try {
      await batch.set(paymentListSantriRef, setPaymentData, { merge: true });
      batch.commit();
      setState(true);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const removePayment = async () => {
    const batch = firestore.batch();
    const santriRef = firestore.collection('santri').doc(`${dataSantri.noRekening}`);
    const paymentSantriRef = santriRef.collection('payment').doc(`${data.id}`);
    const paymentListSantriRef = paymentSantriRef.collection('list-payment').doc(`${paymentData.id}`);
    try {
      // paymentSantriRef.delete();
      await batch.delete(paymentListSantriRef);
      batch.commit();
      setState(false);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const handleChange = () => {
    if (state === true) {
      removePayment();
    } else {
      addPayment();
    }
  };

  return (
    <FormControlLabel
      control={(
        <GreenCheckbox
          checked={state}
          onChange={handleChange}
          name="checkedG"
        />
      )}
    />
  );
}
