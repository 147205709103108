import React, { useState, useEffect } from 'react'
import clsx from 'clsx';
import { 
  Paper, 
  CircularProgress, 
  TextField, 
  Button,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
  Input
} from '@material-ui/core';
import { firestore } from 'src/config/firebase';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    
  },
}));

const Index = () => {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [contact, setContact] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      await firestore.collection('config').doc('cs')
          .onSnapshot((querySnapshot) => {
              setData(querySnapshot.data())
          });
    } catch (err) {
      console.log(err);
      alert(`${err}`)
    }
  }

  const handleAddNumber = async () => {
    try {
      setLoading(true);
      const ref = firestore.collection('config').doc('cs')
      await ref.set({ contact: firebase.firestore.FieldValue.arrayUnion(contact) }, { merge: true })
      setContact('')
      setLoading(false);
    } catch (err) {
      console.log(err);
      alert(err);
      setLoading(false);
    }
  }

  const handleDeleteContact = async (e) => {
    try {
      setLoading(true);
      const ref = firestore.collection('config').doc('cs')
      await ref.set({ contact: firebase.firestore.FieldValue.arrayRemove(e) }, { merge: true })
      setContact('')
      setLoading(false);
    } catch (err) {
      console.log(err);
      alert(err);
      setLoading(false);
    }
  }

  if (data === null) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div style={{width: '100%', height: '100%', padding: 10}}>
      <Paper style={{flexDirection: 'column', padding: 10}}>
        {
          data.contact.map((e, index) => {
            return (
              <FormControl 
                className={clsx(classes.margin, classes.textField)}
                margin='normal'
                fullWidth
                key={index}
              >
                <InputLabel htmlFor="list-kontak">Kontak</InputLabel>
                <Input
                  id='list-kontak'
                  value={e}
                  contentEditable={false}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleDeleteContact(e)}>
                        <DeleteIcon color='secondary' />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            )
          })
        }
      </Paper>
      <Paper style={{padding: 10, marginTop: 5}}>
        <TextField
          value={contact}
          margin='normal'
          label='Tambah Kontak'
          fullWidth
          onChange={(e) => setContact(e.target.value)} />
        <Button disabled={loading} variant='contained' color={loading ? 'inherit' : 'primary'} onClick={handleAddNumber}>
          { loading ? 'Menyimpan...' : 'Simpan' }
        </Button>
      </Paper>
    </div>
  )
}

export default Index
