import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  RowDetailState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  PagingPanel,
  TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';
import { Button, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import { green } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import Page from 'src/components/Page';
import { firestore } from 'src/config/firebase';
import AddSantriDialog from './payment/AddSantriDialog'
import EditButton from '../components/editSantri';
import MenuButton from './menu';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(1)
  },
  productCard: {
    height: '100%'
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

function Index() {
  const classes = useStyles();
  const theme = useTheme();
  const user = useSelector(state => state.user.profile);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizes] = useState([5, 10, 25, 50, 100, 250, 500, 1000, 0]);
  const [open, setOpen] = useState(false);
  const [defaultHiddenColumnNames] = useState(['edit']);
  const [rows, setRows] = useState(null)

  useEffect(() => {
    getDatafromDB()
  }, [])

  const getDatafromDB = async () => {
    try {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        await firestore.collection('santri')
          .limit(5)
          .orderBy('name', 'asc')
          .onSnapshot(function(doc) {
              const getData = doc.docs.map((item) => item.data());
              setRows(getData);
          });
      } else {
        await firestore.collection('santri').orderBy('name', 'asc')
          .onSnapshot(function(doc) {
              const getData = doc.docs.map((item) => item.data());
              setRows(getData);
          });
      }
    } catch (err) {
      alert(err);
      console.log(err);
    }
  }

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  const columns = [
    {
      name: 'edit',
      title: 'Edit Data',
      getCellValue: (row) => <EditButton data={row} /> 
    },
    {
      name: 'id',
      title: 'ID Santri',
      getCellValue: (row) => row.noRekening
    },
    {
      name: 'nameSantri',
      title: 'Nama Santri',
      getCellValue: (row) => row.name.toUpperCase()
    },
    {
      name: 'kamar',
      title: 'Kamar Santri',
      getCellValue: (row) => row.kamar.toUpperCase()
    },
    {
      name: 'sekolah',
      title: 'Sekolah',
      getCellValue: (row) => row.sekolah.toUpperCase()
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row) => row.isActive ? 'Aktif' : 'Boyong'
    },
    {
      name: 'menu',
      title: 'Menu',
      getCellValue: (row) => <MenuButton dataSantri={row} />
    }
  ];

  if (rows === null) {
    return (
      <Page
        className={classes.loading}
        title="Pembayaran"
      >
        <CircularProgress color="primary" />
      </Page>
    );
  } else if (user === null) {
    return (
      <div>user null</div>
    )
  } else {
    return (
      <Page
        className={classes.root}
        title="Pembayaran"
      >
        <Paper>
          <Grid
            rows={rows}
            columns={columns}
          >
            <SearchState />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <RowDetailState />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow />
            {
              user.authority.santriData
                ? null
                : <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                  />
            }
            <Toolbar />
            <PagingPanel
              pageSizes={pageSizes}
            />
            <SearchPanel />
          </Grid>
        </Paper>
        <Zoom
          in
          timeout={transitionDuration}
          style={{
            transitionDelay: `${transitionDuration.exit}ms`,
          }}
          unmountOnExit
        >
          <Fab
            aria-label="Withdraw"
            className={clsx(classes.fab, classes.fabGreen)}
            color="primary"
            onClick={() => setOpen(true)}
          >
            <AddIcon />
          </Fab>
        </Zoom>
        <AddSantriDialog open={open} handleClose={() => setOpen(false)}/>
      </Page>
    );
  }
}

export default Index;
