import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PaymentIcon from '@material-ui/icons/Payment';
import { useDispatch } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import PaymentDialog from './PaymentDialog';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

export default function IconButtons({dataSantri}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleOpenPayDialog = () => {
    dispatch.cart.reset();
    setOpen(true);
  }

  return (
    <div className={classes.root}>
      {/* <IconButton aria-label="delete" onClick={handleOpenPayDialog}>
        <PaymentIcon />
      </IconButton> */}

      <ListItem button style={{height: 25}} onClick={handleOpenPayDialog}>
        <ListItemIcon>
          <PaymentIcon />
        </ListItemIcon>
        <ListItemText primary="Payment" />
      </ListItem>
      <PaymentDialog
        dataSantri={dataSantri}
        open={open}
        handleClose={() => setOpen(false)}/>
    </div>
  );
}
