import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import _ from 'lodash';
import { fareFormatter } from 'src/utils/fareFormatter';
import { setData } from './helper';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles((theme) => ({
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    
  },
}));
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Index({ open, handleClose, dataSantri }) {
  const classes = useStyles();
  const cart = useSelector(state => state.cart.cart);
  const user = useSelector(state => state.user.profile);
  const dispatch = useDispatch();
  const [cashIn, setCashIn] = useState(0);
  const [metode, setMetode] = useState('Tunai');
  const [disable, setDisable] = useState(false);

  const handleSetPayment = async () => {
    setDisable(true);
    try {
      setData(dataSantri, cart, user, metode, cashIn);
      dispatch.cart.reset();
      handleClose();
      setDisable(false);
    } catch (err) {
      console.log(err);
      alert(err);
      setDisable(false);
    }
  }

  const totalAmount = cart.map((item) => item.amount).reduce((a, b) => a + b, 0)

  if (cart.length <= 0) {
    return (
      <Dialog 
        onClose={handleClose} 
        aria-labelledby="customized-dialog-title" 
        open={open}
        maxWidth="sm"
        fullWidth>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Cart
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            Tidak Ada Pembayaran
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Dialog 
      onClose={handleClose} 
      aria-labelledby="customized-dialog-title" 
      open={open}
      maxWidth="sm"
      fullWidth>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Cart
      </DialogTitle>
      <DialogContent dividers>
        {
          cart.map((item, index) => {
            return (
              <TextField
                key={`list-value-${index}`}
                label={_.upperCase(item.name)}
                value={fareFormatter(item.amount)}
                contentEditable={false}
                margin="dense"
                fullWidth />
            )
          })
        }
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Total Pembayaran"
              value={fareFormatter(totalAmount)}
              contentEditable={false}
              margin="dense"
              fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Uang Masuk"
              value={cashIn}
              margin="dense"
              fullWidth
              onChange={(e) => setCashIn(e.target.value)} />
          </Grid>
          <FormControl fullWidth className={clsx(classes.margin, classes.withoutLabel, classes.textField)}>
            <InputLabel>Metode Pembayaran</InputLabel>
            <Select
              value={metode}
              onChange={(e) => setMetode(e.target.value)}
            >
              <MenuItem value="Tunai">Tunai</MenuItem>
              <MenuItem value="Dari Tabungan">Dari Tabungan</MenuItem>
              <MenuItem value="Transfer">Transfer</MenuItem>
              <MenuItem value="Lebihan Pembayaran">Lebihan Pembayaran</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Kembalian"
            value={fareFormatter(cashIn-totalAmount)}
            margin="dense"
            fullWidth
            variant="outlined"
            color="primary"
            focused
            contentEditable={false} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={disable} variant="contained" autoFocus onClick={handleSetPayment} color="primary">
          Bayar Sekarang
        </Button>
        <Button variant="outlined" autoFocus onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
