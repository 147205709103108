import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { firestore } from 'src/config/firebase';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function FormDialog({open, handleClose}) {
  const classes = useStyles();

  const [name, setname] = useState('');
  const [alamat, setAlamat] = useState('');
  const [ayah, setAyah] = useState('');
  const [ibu, setIbu] = useState('');
  const [kamar, setKamar] = useState('');
  const [kelamin, setKelamin] = useState('');
  const [kontakAyah, setKontakAyah] = useState('');
  const [kontakIbu, setKontakIbu] = useState('');
  const [nisn, setNisn] = useState('');
  const [noRekening, setNoRekening] = useState('');
  const [sekolah, setSekolah] = useState('');
  const [tanggalLahir, setTanggalLahir] = useState('');
  const [tempatLahir, setTempatLahir] = useState('');
  const [loading, setLoading] = useState(false);
  const [ime, setIme] = useState('');

  const checkNoRekening = async () => {
    const ref = await firestore.collection('santri').doc(`${noRekening.trim()}`).get();
    try {
      if (ref.exists) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      alert(error);
    }
  }

  const handleSave = async () => {
    setLoading(true)
    const ref = firestore.collection('santri').doc(`${noRekening.trim()}`)
    try {
      const checkIfAvailable = await checkNoRekening()
      if (checkIfAvailable) {
        await ref.set({
          alamat: alamat,
          name: name,
          ayah: ayah,
          ibu: ibu,
          kamar: kamar,
          kelamin: kelamin,
          kontakAyah: kontakAyah,
          kontakIbu: kontakIbu,
          nisn: nisn,
          noRekening: noRekening,
          sekolah: sekolah,
          tanggalLahir: tanggalLahir,
          tempatLahir: tempatLahir,
          ime: ime,
          isActive: true,
          saku: {
            updateAt: new Date(),
            value: 0
          },
          tabungan: {
            updateAt: new Date(),
            value: 0
          }
        })
        alert('Berhasil Menyimpan Data Santri');
        handleClose();
        setLoading(false);
      } else {
        alert('Nomor Induk Telah DiPakai !!!');
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      alert(error);
      setLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth='lg' fullWidth>
      <DialogTitle id="form-dialog-title">Input Data Santri</DialogTitle>
      <DialogContent>
        <Grid container justifyContent='space-around' alignItems='center' spacing={2}>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              id="noRekening"
              label="No. Induk"
              fullWidth
              value={noRekening}
              onChange={(e) => setNoRekening(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="ime"
              label="No. IME"
              fullWidth
              value={ime}
              onChange={(e) => setIme(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nama"
              fullWidth
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="alamat"
              label="Alamat"
              fullWidth
              value={alamat}
              onChange={(e) => setAlamat(e.target.value)}
              multiline
            />
            <TextField
              autoFocus
              margin="dense"
              id="kelamin"
              label="Jenis Kelamin"
              fullWidth
              value={kelamin}
              onChange={(e) => setKelamin(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="kamar"
              label="Kamar"
              fullWidth
              value={kamar}
              onChange={(e) => setKamar(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="sekolah"
              label="Sekolah"
              fullWidth
              value={sekolah}
              onChange={(e) => setSekolah(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              id="tempatLahir"
              label="Tempat Lahir"
              fullWidth
              value={tempatLahir}
              onChange={(e) => setTempatLahir(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="tanggalLahir"
              label="Tanggal Lahir"
              fullWidth
              value={tanggalLahir}
              onChange={(e) => setTanggalLahir(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="nisn"
              label="NISN (Nomor Induk Siswa Nasional)"
              fullWidth
              value={nisn}
              onChange={(e) => setNisn(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="ayah"
              label="Ayah"
              fullWidth
              value={ayah}
              onChange={(e) => setAyah(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="kontakAyah"
              label="Kontak Ayah"
              fullWidth
              value={kontakAyah}
              onChange={(e) => setKontakAyah(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="ibu"
              label="Ibu"
              fullWidth
              value={ibu}
              onChange={(e) => setIbu(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="kontakIbu"
              label="Kontak Ibu"
              fullWidth
              value={kontakIbu}
              onChange={(e) => setKontakIbu(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave} color="primary">
          Simpan
        </Button>
      </DialogActions>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}
