import React from 'react';
import { Button } from '@material-ui/core';
import { firestore } from 'src/config/firebase.js';
import firebase from 'firebase'

const Index = () => {

  const handleClick = async () => {
    try {
      const santriData = (
        await firestore.collection('santri').get()
      )
      santriData.forEach((e) => {
        const ref = firestore.collection('santri').doc(e.id)
                      .collection('payment').doc('puq50Nu3829xYJtlMAsM')
                      .collection('list-payment').doc('CS9mckkWaCW4yHxTAVPm')
        ref.delete()
        console.log(e.id)
      })
      // await firestore.doc(`santriDebug/00-000001`)
      //     .set({
      //       tabungan: {
      //         value: firebase.firestore.FieldValue.increment(-20000),
      //         updateAt: new Date()
      //       }
      //     }, { merge: true })
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div style={{margin: 50}}>
      <Button onClick={handleClick}>
        Set Data
      </Button>
    </div>
  )
}

export default Index
