import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { firestore } from 'src/config/firebase';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(0.5),
  },
}));

function AcceptButton({ data }) {
  const classes = useStyles();
  const user = useSelector(state => state.user.profile);
  const [reasonReject, setReasonReject] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const accept = async () => {
    if (user.authority.payment) {
      setLoading(true);
      const batch = firestore.batch();
      // ref
      const paymentRef = firestore.collection('paymentHistory').doc(`${data.id}`);
      const santriHistoryPaymentRef = firestore.doc(`santri/${data.userData.noRekening}/paymentHistory/${data.id}`);
      const santriRef = firestore.collection('santri').doc(`${data.userData.noRekening}`);
      // data
      const acceptData = {
        status: 'done',
        updateAt: new Date(),
        confirmAt: new Date(),
        tellerData: user
      };
      try {
        await batch.set(paymentRef, acceptData, { merge: true });
        await batch.set(santriHistoryPaymentRef, acceptData, { merge: true });
        data.paymentDataId.forEach((a) => {
          batch.set(
              santriRef.collection('payment').doc(`${data.paymentID}`).collection('list-payment').doc(`${a.id}`),
              {
                values: a.amount,
              }, { merge: true }
            )
        });
        await batch.commit();
        setLoading(false);
      } catch (error) {
        console.log(error);
        alert(error);
        setLoading(false);
      }
    } else {
      alert('Akses Ditolak !')
      console.log(user.authority.payment)
    }
  };
  const reject = async () => {
    if (user.authority.payment) {
      setLoading(true);
      const batch = firestore.batch();
      // ref
      const paymentRef = firestore.collection('paymentHistory').doc(`${data.id}`);
      const santriHistoryPaymentRef = firestore.doc(`santri/${data.userData.noRekening}/paymentHistory/${data.id}`);
      // data
      const acceptData = {
        status: 'reject',
        updateAt: new Date(),
        confirmAt: new Date(),
        rejectReason: reasonReject,
        tellerData: user
      };
      try {
        batch.set(paymentRef, acceptData, { merge: true });
        batch.set(santriHistoryPaymentRef, acceptData, { merge: true });
        batch.commit();
        setLoading(false);
      } catch (error) {
        console.log(error);
        alert(error);
        setLoading(false);
      }
    } else {
      alert('Akses Ditolak !')
      console.log(user.authority.payment)
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const deletePayment = async () => {
    if (user.authority.payment) {
      setLoading(true);
      const batch = firestore.batch();
      // ref
      const paymentRef = firestore.collection('paymentHistory').doc(`${data.id}`);
      const santriHistoryPaymentRef = firestore.doc(`santri/${data.userData.noRekening}/paymentHistory/${data.id}`);
      const santriRef = firestore.collection('santri').doc(`${data.userData.noRekening}`);
      try {
        batch.delete(paymentRef);
        batch.delete(santriHistoryPaymentRef);
        data.paymentDataId.forEach((a) => {
          batch.set(
              santriRef.collection('payment').doc(`${data.paymentID}`).collection('list-payment').doc(`${a.id}`),
              {
                isConfirm: false,
                isPaid: false,
              }, { merge: true }
            )
        });
        batch.commit();
        setLoading(false);
      } catch (error) {
        console.log(error);
        alert(error);
        setLoading(false);
      }
    } else {
      alert('Akses Ditolak !')
      console.log(user.authority.payment)
    }
  }

  return (
    <div className={classes.root}>
      <IconButton color="primary" aria-label="upload picture" onClick={handleClick}>
        <AccountBalanceWalletIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={accept}>Terima</MenuItem>
        <MenuItem onClick={() => setOpen(true)}>Tolak</MenuItem>
        <MenuItem onClick={deletePayment}>Hapus</MenuItem>
      </Menu>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <TextField
            id="reason reject"
            label="Alasan Tolak"
            value={reasonReject}
            onChange={(e) => setReasonReject(e.target.value)}
            fullWidth
            style={{ width: '30vw' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Batal
          </Button>
          <Button onClick={reject} color="primary" autoFocus>
            Tolak
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

AcceptButton.propTypes = {
  data: PropTypes.object
};

export default AcceptButton;
