const initState = {
  cart: [],
};

export default {
  state: initState,
  reducers: {
    reset: () => ({ ...initState }),
    setCart: (state, cart) => ({ ...state, cart }),
  }
};
