import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { firestore } from 'src/config/firebase';
import { Select, LinearProgress, Divider, ListItemSecondaryAction, InputLabel, MenuItem, IconButton, List, Typography, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { fareFormatter } from 'src/utils/fareFormatter';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import HelpIcon from '@material-ui/icons/Help';
import { useSelector } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloseIcon from '@material-ui/icons/Close';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import EditPembayaran from './editPayment';
import InputValue from './inputValues';
import EditStatus from './isEdit';
import { makeid } from 'src/utils/id';
import { check } from 'src/utils/checkPayment';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    padding: theme.spacing(2),
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '40ch',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ListItemPayment = ({ user, item, setUpdateData, setItemData, setOpenDettail, status, handleEdit, handleOpen, dataSantri, paymentData }) => {
  const cart = useSelector(state => state.cart.cart);
  const [isExist, setIsExist] = useState(false);

  useEffect(() => {
    checkPayment()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const checkPayment = async () => {
    try {
      const paymentExist = (
          await firestore.collection('payment').doc(`${paymentData.id}`)
            .collection('list-payment').doc(`${item.id}`).get()
        ).exists
      setIsExist(paymentExist)
    } catch (err) {
      console.log(err)
    }
  }

  const getData = (item) => {
    firestore.collection('santri').doc(`${dataSantri.noRekening}`)
      .collection('payment').doc(`${paymentData.id}`)
      .collection('list-payment').doc(`${item.id}`)
      .collection('update').limit(1).orderBy('createAt', 'desc').get()
      .then((doc) => {
        const a = doc.docs.map((item) => item.data())
        setUpdateData(a[0])
      }).catch((err) => console.log(err))
  }

  const openEditDettail = (item) => {
    if (dataSantri !== undefined) {
      getData(item);
      setItemData(item);
      setOpenDettail(true);
    }
  }
  const PrimaryComponent = ({ item }) => {
    return (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Typography>
          {`${item.name.toUpperCase()} - ${item.multiple ? '' : fareFormatter(item.value)}`}
        </Typography>
        {
          item.isEdited
            ? <IconButton size="small" onClick={() => openEditDettail(item)}>
                <HelpIcon fontSize="small" />
              </IconButton>
            : null
        }
      </div>
    )
  }
  if (isExist) {
    return (
      <div>
        <ListItem>
          {
            user.authority.payment
              ? <ListItemIcon>
                  <IconButton disabled={item.isPaid} onClick={handleEdit}>
                    <EditIcon />
                  </IconButton>
                </ListItemIcon>
              : null
          }
          <ListItemText 
            primary={<PrimaryComponent item={item}/>}
            secondary={status} />
          {
            user.authority.payment
              ? <ListItemSecondaryAction>
                  {
                    cart.map((a) => a.id).includes(item.id)
                      ? <IconButton>
                          <ShoppingBasketIcon/>
                        </IconButton>
                      : null
                  }
                  <IconButton disabled={item.isPaid} onClick={handleOpen}>
                    <AccountBalanceWalletIcon/>
                  </IconButton>
                </ListItemSecondaryAction>
              : null
          }
        </ListItem>
        <Divider />
      </div>
    )
  }
  return null
}

export default function ScrollDialog({ open, handleClose, paymentData, dataSantri }) {
  const classes = useStyles();
  const user = useSelector(state => state.user.profile);
  const [isCicil, setIsCicil] = useState(false);
  const [data, setData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [openDettail, setOpenDettail] = useState(false);
  const [itemData, setItemData] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [cicilData, setCicilData] = useState([]);
  const [dibayarkan, setDibayarkan] = useState('');
  const [metode, setMetode] = useState('Tunai');
  const [loading, setLoading] = useState(false);
  const [listPaymentData, setListPaymentData] = useState(null);

  const totalAmount = cicilData.map((a) => a.amount).reduce((b, c) => b + c, 0)

  const getData = async () => {
    try {
      await firestore.collection('santri').doc(`${dataSantri.noRekening}`)
        .collection('payment').doc(`${paymentData.id}`)
        .collection('list-payment').orderBy('name', 'desc')
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((item) => {
                          let a;
                          a = item.data();
                          a.id = item.id;
                          return a;
                        });
          setListPaymentData(data)
        })
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const handleCloseDialog = () => {
    setCicilData([]);
    handleClose();
    console.log('Close')
  }
  const handleOpen = (val) => {
    setIsCicil(true);
    setData(val);
  }
  const handleEdit = (item) => {
    if (item.isPaid) {
      console.log("Sudah Dibayar", item.isPaid)
    } else {
      setEditData(item);
      setIsEdit(true);
    }
  }

  const handleDelete = (item) => {
    // console.log(item)
    //! Check Index Array
    var indexItem
    if (cicilData >= 1) {
      indexItem = cicilData.map((a) => a).findIndex((b) => item.id)
    } else {
      indexItem = []
    }
    //! find item 
    var found
    for(var i = 0; i < cicilData.length; i++) {
        if (cicilData[i].id == item.id) {
            found = true;
        }
    }
    if (found) {
      var a = cicilData;
      var removeItem = [item.id];
      removeItem.forEach(function(id){
          var itemIndex = a.findIndex(i => i.id == id);
          a.splice(itemIndex,1);
      });
      setItemData(a);
    } 
  }
  const handleSubmit = async () => {
    setLoading(true);
    if (cicilData.length <= 0) {
      console.log('close Only')
      setCicilData([]);
      handleClose();
      setLoading(false);
    } else {
      console.log('close and set data')
      const batch = firestore.batch();
      const historyId = makeid(16) 
      // const santriRef = firestore.collection('santri').doc(`${dataSantri.noRekening}`)
      const ref = firestore.collection('santri').doc(`${dataSantri.noRekening}`)
                    .collection('payment').doc(`${paymentData.id}`)
      const paymentHistoryRef = firestore.collection('paymentHistory').doc(`${historyId}`)
      const santriHistoryRef = firestore.collection('santri').doc(`${dataSantri.noRekening}`)
                                .collection('paymentHistory').doc(`${historyId}`)
      const historyData = {
        createAt: new Date(),
        id: historyId,
        name: paymentData.name,
        paymentCode: 'manual',
        status: 'done',
        totalAmount: parseInt(totalAmount),
        updateAt: new Date(),
        userData: dataSantri,
        paymentID: paymentData.id,
        isManual: true,
        paymentDataId: cicilData,
        uangMasuk: parseInt(dibayarkan),
        tellerData: user,
        metode: metode
      }
      try {
        cicilData.forEach((a) => {
          // const paymentRef = santriRef.collection('payment').doc(`${a.paymentId}`).collection('list-payment').doc(`${a.id}`)
          // batch.set(paymentRef, a, { merge: true });
          batch.set(ref.collection('list-payment').doc(`${a.id}`), a, { merge: true });
        })
        batch.set(paymentHistoryRef, historyData, { merge: true });
        batch.set(santriHistoryRef, historyData, { merge: true });
        await batch.commit();
        const isSuccees = await check(historyId)
        console.log(isSuccees)
        if (isSuccees.value === true) {
          alert('Sukses');
          setCicilData([]);
          handleClose();
          setLoading(false);
        } else if (isSuccees.value === false) {
          alert('Pembayaran Gagal!');
          setLoading(false);
        } else {
          alert('Terjadi Kesalahan Saat Memproses Data');
          setLoading(false);
        }
      } catch (error) {
        alert(error);
        console.log(error);
        setLoading(false);
      }
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle
          id="scroll-dialog-title"
          className={classes.title}>
            <Typography>{paymentData.name.toUpperCase()}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
              <CloseIcon color="inherit" />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <List>
                { 
                  listPaymentData === null 
                    ? <LinearProgress /> 
                    : listPaymentData.map((item) => {
                        const dibayar = item.values === undefined ? 0 : item.values;
                        const kurang = item.values === undefined ? item.value : parseInt(item.value) - parseInt(item.values)
                        const status = item.multiple 
                                        ? (
                                          item.isPaid 
                                            ?  `Dibayar ${fareFormatter(dibayar)} - (Lunas)`
                                            : ''
                                          ) 
                                        : item.isPaid 
                                          ? '(Lunas)' 
                                          : `Dibayar ${fareFormatter(dibayar)}, Kurang ${fareFormatter(kurang)}`
                        return (
                          <div key={item.id}>
                            <ListItemPayment
                              user={user}
                              item={item}
                              status={status}
                              handleEdit={() => handleEdit(item)}
                              handleOpen={() => handleOpen(item)}
                              dataSantri={dataSantri}
                              paymentData={paymentData}
                              setItemData={setItemData}
                              setOpenDettail={setOpenDettail}
                              setUpdateData={setUpdateData} />
                          </div>
                        )
                      })
                }
              </List>
            </Grid>
            <Grid item xs={5}>
              <Paper className={classes.paper}>
                {
                  cicilData.map((a, b) => {
                    return (
                      <div key={b}>
                        <FormControl className={clsx(classes.margin, classes.withoutLabel, classes.textField)}>
                          <InputLabel htmlFor="dettail">{a.name}</InputLabel>
                          <Input
                            value={fareFormatter(a.amount)}
                            contentEditable={false}
                            id="dettail"
                            margin="dense"
                            endAdornment={
                              <InputAdornment position="end">  
                                <IconButton onClick={() => handleDelete(a)}>
                                  <HighlightOffIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                    )
                  })
                }
                <FormControl fullWidth className={clsx(classes.margin, classes.withoutLabel, classes.textField)}>
                  <InputLabel htmlFor="dettail">Yang Dibayarkan</InputLabel>
                  <Input
                    value={dibayarkan}
                    onChange={(e) => setDibayarkan(e.target.value)}
                    margin="dense"
                  />
                </FormControl>
                <FormControl fullWidth className={clsx(classes.margin, classes.withoutLabel, classes.textField)}>
                  <InputLabel>Metode Pembayaran</InputLabel>
                  <Select
                    value={metode}
                    onChange={(e) => setMetode(e.target.value)}
                  >
                    <MenuItem value="Tunai">Tunai</MenuItem>
                    <MenuItem value="Dari Tabungan">Dari Tabungan</MenuItem>
                    <MenuItem value="Transfer">Transfer</MenuItem>
                    <MenuItem value="Lebihan Pembayaran">Lebihan Pembayaran</MenuItem>
                  </Select>
                </FormControl>
                <Typography variant="h4" style={{marginTop: 20}}>
                  {`Jumlah: ${fareFormatter(totalAmount)}`}
                </Typography>
                <Typography variant="h4" style={{marginTop: 5}}>
                  {`Dibayarkan: ${fareFormatter(dibayarkan)}`}
                </Typography>
                <Typography variant="h4" style={{marginTop: 5}}>
                  {`Kembalian: ${fareFormatter(dibayarkan - totalAmount)}`}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained" 
            autoFocus
            disabled={loading}
            onClick={handleSubmit}
            color="primary">
            Konfirmasi
          </Button>
        </DialogActions>
      </Dialog>
      <InputValue
        open={isCicil}
        handleClose={() => setIsCicil(false)}
        listPaymentData={data}
        paymentData={paymentData}
        dataSantri={dataSantri}
        itemData={cicilData}
        setItemData={setCicilData}
      />
      <EditPembayaran 
        data={editData}
        paymentData={paymentData}
        dataSantri={dataSantri}
        open={isEdit}
        handleClose={() => setIsEdit(false)} />
      <EditStatus
        updateData={updateData}
        open={openDettail}
        handleClose={() => setOpenDettail(false)}
        itemData={itemData} />
    </div>
  );
}
