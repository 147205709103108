import React from 'react';
import Switch from '@material-ui/core/Switch';
import { firestore } from '../../../../config/firebase';

export default function Switches({ data, user }) {

  const handleChange = async (event) => {
    if (user.isSuperAuthority) {
      try {
        await firestore.doc(`admin/${data.id}`)
          .set({
            isActive: event.target.checked
          }, { merge: true })
      } catch (error) {
        alert(error);
        console.log(error);
      }
    } else {
      alert('Akses Ditolak !')
    }
  };

  return (
      <Switch
        checked={data.isActive}
        onChange={handleChange}
        color="primary"
        name="checkedB"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
  );
}
