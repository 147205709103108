import React, { useState, useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { ListItemSecondaryAction, Button, Typography } from '@material-ui/core';

// import PaymentItem from './paymentItem';
import Cicilan from './cicilan';
import { firestore } from 'src/config/firebase';

const ListPayments = ({ item, dataSantri, handleOpenCicilan }) => {
  const [exist, setExist] = useState(false);
  const [status, setStatus] = useState('(Memuat)');
  useEffect(() => {
    checkDoc()
  }, [])
  const checkDoc = async () => {
    try {
      const isExist = (
          await firestore.collection('payment').doc(`${item.id}`).get()
        ).exists
      const payStatus = (
        await firestore.collection('santri').doc(`${dataSantri.noRekening}`)
          .collection('payment').doc(`${item.id}`)
          .collection('list-payment').where('isPaid', '==', false).get()
      ).empty
      setStatus(payStatus ? '(Lunas)' : '')
      setExist(isExist)
    } catch (err) {
      console.log(err)
    }
  }
  if (exist) {
    return (
      <div>
        {/* <ListItem button onClick={() => handleOpen(item)} > */}
        <ListItem>
          <ListItemText 
            primary={`${item.name.toUpperCase()} ${status}`}
            secondary={item.date} />
          <ListItemSecondaryAction>
            <Button 
              variant="outlined" 
              color="primary"
              onClick={handleOpenCicilan}>
              Pembayaran
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </div>
    )
  }
  return <div />
}

export default function PaymentList({ data, dataSantri }) {
  // const [open, setOpen] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [openCicilan, setOpenCicilan] = useState(false);

  const handleOpenCicilan = (value) => {
    setOpenCicilan(true);
    setPaymentData(value);
  }

  return (
    <>
      {
        data.map((item) => {
          return (
            <ListPayments 
              item={item} 
              dataSantri={dataSantri} 
              handleOpenCicilan={() => handleOpenCicilan(item)}
              key={item.id} />
          )
        })
      }
      {
        paymentData === null
          ? <div/>
          : <Cicilan
              open={openCicilan}
              handleClose={() => setOpenCicilan(false)}
              paymentData={paymentData}
              dataSantri={dataSantri}
            />
      }
      {/* {
        paymentData === null
          ? <div/>
          : <PaymentItem
              open={open}
              handleClose={() => setOpen(false)}
              paymentData={paymentData}
              dataSantri={dataSantri}
            />
      } */}
    </>
  );
}
