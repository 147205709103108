import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ListIcon from '@material-ui/icons/List';
import Detail from './payment/dialog';
import Payment from './payment/payMethod';
import Print from './payment/print';
import ResetPassword from './payment/resetPassword';
import Delete from './payment/delete';
import { useSelector } from 'react-redux';;

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
  },
}))(MenuItem);

export default function CustomizedMenus({ dataSantri }) {
  const user = useSelector(state => state.user);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick} color="primary">
        <ListIcon />
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <Detail dataSantri={dataSantri} />
        </StyledMenuItem>
        <StyledMenuItem>
          <Payment dataSantri={dataSantri} />
        </StyledMenuItem>
        <StyledMenuItem>
          <Print dataSantri={dataSantri} />
        </StyledMenuItem>
        {
          user.profile.id === 'BMA01'
            ? <div>
                <StyledMenuItem>
                  <ResetPassword dataSantri={dataSantri} />
                </StyledMenuItem>
                <StyledMenuItem>
                  <Delete dataSantri={dataSantri} />
                </StyledMenuItem>
              </div>
            : null
        }
      </StyledMenu>
    </div>
  );
}
