import React from 'react';
import { Button } from '@material-ui/core';
import { firestore, storage } from 'src/config/firebase';


export default function MediaCard({ row }) {

  const handleDelete = async () => {
    try {
      const firestoreRef = firestore.collection('pengumuman').doc(`${row.id}`)
      if (row.image === null) {
        await firestoreRef.delete()
      } else {
        const storageRef = storage.ref().child(`pengumuman/${row.id}.jpg`)
        await firestoreRef.delete()
        await storageRef.delete()
      }
    } catch (err) {
      console.log(err);
      alert(`${err}`)
    }
  }

  return (
    <div style={{padding: 10}}>
      <Button variant='contained' color='secondary' onClick={handleDelete} size='small'>
        Hapus
      </Button>
    </div>
  );
}
