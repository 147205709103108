import React, { useState, useEffect } from 'react'
import { Paper, LinearProgress } from '@material-ui/core';
import {
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { firestore } from 'src/config/firebase';
import TotalAmount from './income';

const Admin = ({ startDate }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const read = (
        await firestore.collection("admin").orderBy('name', 'asc').get()
      ).docs.map(e => e.data())
      setData(read)
    } catch (err) {
      console.log(err);
      alert(err)
    }
  }

  const columns = [
    {
      name: 'id',
      title: 'ID Admin',
      getCellValue: (row) => row.id
    },
    {
      name: 'name',
      title: 'Nama Admin',
      getCellValue: (row) => row.name
    },
    {
      name: 'totalAmount',
      title: 'Uang Masuk',
      getCellValue: (row) => <TotalAmount adminData={row} startDate={startDate.startOf('day')} />
    },
  ];

  if (data === null) {
    return (
      <LinearProgress />
    )
  }
  return (
    <Paper>
      <Grid
        rows={data}
        columns={columns}
      >
        <SearchState />
        <IntegratedFiltering />
        <PagingState
          defaultCurrentPage={0}
          pageSize={5}
        />
        <IntegratedPaging />
        <Table />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel />
        <PagingPanel />
      </Grid>
    </Paper>
  )
}

export default Admin
