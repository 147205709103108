import React, { useState } from 'react';
import {
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import AddAdminScreen from './addAdmin';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const SettingsView = () => {
  const classes = useStyles();
  const [expandSettings, setExpandSettings] = useState(false);

  const handleClick = () => {
    setExpandSettings(!expandSettings);
  };
  return (
    <Page
      className={classes.root}
      title="Settings"
    >
      <Container maxWidth="lg">
        <ListItem button onClick={handleClick} style={{backgroundColor: 'white'}}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Tambahkan Admin" />
          {expandSettings ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expandSettings} unmountOnExit>
          <AddAdminScreen />
        </Collapse>
      </Container>
    </Page>
  );
};

export default SettingsView;
