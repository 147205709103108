import styled from '@react-pdf/styled-components';
import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    // alignItems: 'center'
  },
  logo: {
    width: 50, 
    height: 50, 
    // marginTop: 25, 
    marginLeft: 7.5
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  line: {
    width: 2.5, 
    height: 50,
    backgroundColor: 'black',
    // marginTop: 25,
    marginHorizontal: 10
  },
  KopHeadBold: {
    fontWeight: 'bold',
    fontSize: 11
  },
  kopSubHead: {
    fontSize: 8
  }
});