import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import SettingsView from 'src/views/settings/SettingsView';
import Payment from 'src/views/payment';
import Tabungan from 'src/views/tabungan';
import Saku from 'src/views/saku';
import DataSantri from 'src/views/santri/data';
import PaymentConfig from 'src/views/paymentConfig';
import TransTabungan from 'src/views/tabungan/transaksi';
import TransSaku from 'src/views/saku/transaksi';
import Arrears from 'src/views/payment/components/arrears';
import Dashboard from 'src/views/dashboard';
import Pengumuman from 'src/views/pengumuman';

//* apk config
import BannerPage from 'src/views/settings/app/banner';
import CsPage from 'src/views/settings/app/cs'

//* Admin
import DataAdmin from 'src/views/admin/data';
import AddAdmin from 'src/views/admin/data/Add/addAdmin';

import TestData from 'src/bots';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'payment', element: <Payment /> },
      { path: 'tabungan-santri', element: <Tabungan /> },
      { path: 'saku-santri', element: <Saku /> },
      { path: 'data-santri', element: <DataSantri /> },
      { path: 'settings-payments', element: <PaymentConfig /> },
      { path: 'admin/data-admin', element: <DataAdmin /> },
      { path: 'admin/add-admin', element: <AddAdmin /> },
      { path: 'tabungan/transaksi-tabungan', element: <TransTabungan /> },
      { path: 'saku/transaksi-saku', element: <TransSaku /> },
      { path: 'payment/arrears-payments', element: <Arrears /> },
      { path: 'test-data', element: <TestData /> },
      { path: 'home-dashboard', element: <Dashboard /> },
      { path: 'banner/page', element: <BannerPage /> },
      { path: 'customer-services/page', element: <CsPage /> },
      { path: 'pengumuman', element: <Pengumuman /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
