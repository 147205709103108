import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { fareFormatter } from 'src/utils/fareFormatter';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  formControl: {
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const InputValue = ({ open, handleClose, listPaymentData, dataSantri, paymentData, itemData, setItemData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart.cart);
  const [value, setValue] = useState(0);
  const [isLoading] = useState(false);

  useEffect(() => {
    setValue(0);
    // dispatch.cart.reset()
  }, [])

  const removeItem = (item) => {
    var a = itemData;
    var removeItem = [item.id];
    removeItem.forEach(function(id){
        var itemIndex = a.findIndex(i => i.id === id);
        a.splice(itemIndex,1);
    });
    setItemData(a);
  }

  const removeItemCart = (item) => {
    var a = cart;
    var removeItem = [item.id];
    removeItem.forEach(function(id){
        var itemIndex = a.findIndex(i => i.id === id);
        a.splice(itemIndex,1);
    });
    dispatch.cart.setCart(a);
  }

  const handleSubmit = async () => {
    const item = listPaymentData
    // console.log(item)
    //! Check Index Array
    var indexItem
    if (itemData >= 1) {
      indexItem = itemData.map((a) => a).findIndex((b) => item.id)
    } else {
      indexItem = []
    }
    //! find item 
    var found
    for(var i = 0; i < itemData.length; i++) {
        if (itemData[i].id === item.id) {
            found = true;
        }
    }
    if (found) {
      console.log('remove')
      if (indexItem <= -1) {
        alert('Terjadi Kesalahan indexItem <= -1')
      } else {
        try {
          await removeItem(item);
          setItemData(_.concat(itemData, {
                amount: parseInt(value),
                createAt: item.createAt,
                id: item.id,
                paymentId: paymentData.id,
                isPaid: item.isPaid,
                multiple: item.multiple,
                name: item.name,
                value: item.value,
                values: item.values === undefined ? parseInt(value) : item.values + parseInt(value)
              })
            );
          console.log(item.values === undefined ? parseInt(value) : item.values + parseInt(value))
          handleClose();
        } catch (error) {
          console.log(error);
          alert(error);
        }
      }
    } else {
      try {
        console.log('push');
        setItemData(_.concat(itemData, {
                amount: parseInt(value),
                createAt: item.createAt,
                id: item.id,
                paymentId: paymentData.id,
                isPaid: item.isPaid,
                multiple: item.multiple,
                name: item.name,
                value: item.value,
                values: item.values === undefined ? parseInt(value) : item.values + parseInt(value)
              })
            );
        console.log(item.values === undefined ? parseInt(value) : item.values + parseInt(value))
        handleClose();
      } catch (error) {
        console.log(error);
        alert(error);
      }
    }
  }

  const handleCart = async () => {
    const item = listPaymentData
    //! Check Index Array
    var indexItem
    if (cart >= 1) {
      indexItem = cart.map((a) => a).findIndex((b) => item.id)
    } else {
      indexItem = []
    }
    //! find item 
    var found
    for(var i = 0; i < cart.length; i++) {
        if (cart[i].id === item.id) {
            found = true;
        }
    }
    if (found) {
      console.log('remove')
      if (indexItem <= -1) {
        alert('Terjadi Kesalahan indexItem <= -1')
      } else {
        try {
          await removeItemCart(item);
          const pushData = {
            amount: parseInt(value),
            createAt: item.createAt,
            id: item.id,
            paymentId: paymentData.id,
            isPaid: item.isPaid,
            multiple: item.multiple,
            name: item.name,
            value: item.value,
            values: item.values === undefined ? parseInt(value) : item.values + parseInt(value)
          }
          dispatch.cart.setCart(_.concat(cart, pushData));
          handleClose();
        } catch (error) {
          console.log(error);
          alert(error);
        }
      }
    } else {
      try {
        console.log('push');
        const pushData = {
          amount: parseInt(value),
          createAt: item.createAt,
          id: item.id,
          paymentId: paymentData.id,
          isPaid: item.isPaid,
          multiple: item.multiple,
          name: item.name,
          value: item.value,
          values: item.values === undefined ? parseInt(value) : item.values + parseInt(value)
        }
        dispatch.cart.setCart(_.concat(cart, pushData));
        handleClose();
      } catch (error) {
        console.log(error);
        alert(error);
      }
    }
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  if (listPaymentData === null) {
    return (
      <div/>
    )
  }

  console.log(cart)
  return (
    <Dialog 
      maxWidth="sm" 
      fullWidth 
      open={open} 
      onClose={handleClose} 
      aria-labelledby="form-dialog-title"
      keepMounted={false}>
      <DialogContent>
        {
          listPaymentData.multiple
            ? <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="value">{`Pilih Nominal ${listPaymentData.name}`}</InputLabel>
                <Select
                  labelId="value"
                  id="value"
                  value={value}
                  onChange={handleChange}
                >
                  {
                    listPaymentData.value.map((a, idx) => {
                      return (
                        <MenuItem key={idx} value={a}>{fareFormatter(a)}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            : <>
                <TextField
                  autoFocus
                  margin="dense"
                  id="value" 
                  label={`Masukkan Nominal Pembayaran ${listPaymentData.name}`}
                  type="number"
                  fullWidth
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
                <Typography>
                  {`Nominal: ${fareFormatter(value)}`}
                </Typography>
              </>
        }
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose} color="secondary">
          Batal
        </Button>
        <Button disabled={isLoading} variant="contained" onClick={handleCart} color="primary">
          Masukkan Ke Cart
        </Button>
        <Button disabled={isLoading} variant="contained" onClick={handleSubmit} color="primary">
          Konfirmasi
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InputValue