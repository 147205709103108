import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import _ from 'lodash';

export default function FormDialog({open, handleClose, data}) {
  const [name, setname] = useState(data.name);
  const [alamat, setAlamat] = useState(data.alamat);
  const [ayah, setAyah] = useState(data.ayah);
  const [ibu, setIbu] = useState(data.ibu);
  const [kamar, setKamar] = useState(data.kamar);
  const [kelamin, setKelamin] = useState(data.kelamin);
  const [kontakAyah, setKontakAyah] = useState(data.kontakAyah);
  const [kontakIbu, setKontakIbu] = useState(data.kontakIbu);
  const [noRekening, setNoRekening] = useState(data.noRekening);
  const [sekolah, setSekolah] = useState(data.sekolah);
  const [tanggalLahir, setTanggalLahir] = useState(data.tanggalLahir);
  const [tempatLahir, setTempatLahir] = useState(data.tempatLahir);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Data Santri</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="noRekening"
          label="No. Induk"
          fullWidth
          value={noRekening}
          contentEditable={false}
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nama"
          fullWidth
          value={name}
          contentEditable={false}
        />
        <TextField
          autoFocus
          margin="dense"
          id="alamat"
          label="Alamat"
          fullWidth
          value={alamat}
          contentEditable={false}
        />
        <TextField
          autoFocus
          margin="dense"
          id="kamar"
          label="Kamar"
          fullWidth
          value={kamar}
          contentEditable={false}
        />
        <TextField
          autoFocus
          margin="dense"
          id="sekolah"
          label="Sekolah"
          fullWidth
          value={sekolah}
          contentEditable={false}
        />
        <TextField
          autoFocus
          margin="dense"
          id="tempatLahir"
          label="Tempat Lahir"
          fullWidth
          value={tempatLahir}
          contentEditable={false}
        />
        <TextField
          autoFocus
          margin="dense"
          id="tanggalLahir"
          label="Tanggal Lahir"
          fullWidth
          // value={moment(tanggalLahir.toDate()).format("DD-MM-YYYY")}
          // value={tanggalLahir === '' ? '' : moment(tanggalLahir.toDate()).format("DD-MM-YYYY")}
          value={_.isString(tanggalLahir) ? tanggalLahir : moment(tanggalLahir.toDate()).format("DD-MM-YYYY")}
          contentEditable={false}
        />
        <TextField
          autoFocus
          margin="dense"
          id="ayah"
          label="Ayah"
          fullWidth
          value={ayah}
          contentEditable={false}
        />
        <TextField
          autoFocus
          margin="dense"
          id="kontakAyah"
          label="Kontak Ayah"
          fullWidth
          value={kontakAyah}
          contentEditable={false}
        />
        <TextField
          autoFocus
          margin="dense"
          id="ibu"
          label="Ibu"
          fullWidth
          value={ibu}
          contentEditable={false}
        />
        <TextField
          autoFocus
          margin="dense"
          id="kontakIbu"
          label="Kontak Ibu"
          fullWidth
          value={kontakIbu}
          contentEditable={false}
        />
        <TextField
          autoFocus
          margin="dense"
          id="kelamin"
          label="Jenis Kelamin"
          fullWidth
          value={kelamin}
          contentEditable={false}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  );
}
