import React, { useState, useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PrintIcon from '@material-ui/icons/Print';
import { firestore } from 'src/config/firebaseV1';
import _ from 'lodash';
import ReactExport from "react-data-export";
import { fareFormatter } from 'src/utils/fareFormatter';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
  {
      name: "Johson",
      amount: 30000,
      sex: 'M',
      is_married: true
  },
  {
      name: "Monika",
      amount: 355000,
      sex: 'F',
      is_married: false
  },
  {
      name: "John",
      amount: 250000,
      sex: 'M',
      is_married: false
  },
  {
      name: "Josef",
      amount: 450500,
      sex: 'M',
      is_married: true
  }
];

var dataSet2 = [
  {
      name: "Johnson",
      total: 25,
      remainig: 16
  },
  {
      name: "Josef",
      total: 25,
      remainig: 7
  }
];

const Print = (data) => {
  return (
    <ExcelFile>
      <ExcelSheet data={data} name="Tunggakan_Santri">
        <ExcelColumn 
          label="Nama"
          value={(col) => col.name}/>
      </ExcelSheet>
    </ExcelFile>
  )
}

export default function AlertDialog({ dataSantri }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    getDataPrint()
  }, [])

  const getDataPrint = async () => {
    const paymentRef = firestore.collection('santri').doc(`${dataSantri.noRekening}`).collection('payment')
    var dataArray = []
    try {
      const getPayment = (await paymentRef.get());
      await getPayment.docs.forEach((i) => {
        paymentRef.doc(`${i.id}`).collection('list-payment').where('isPaid', '==', false).get()
          .then((query) => {
            query.forEach((doc) => {
              const querySnapshot = doc.data();
              if (querySnapshot.value !== 0) {
                dataArray.push(querySnapshot);
              }
              setData(dataArray)
            })
          })
      })
    } catch (err) {
      console.log(err);
      alert(err)
    }
  }
  
  return (
    <div>
      <ExcelFile 
        filename={`Tunggakan_${dataSantri.name}`}
        element={
          <ListItem button style={{height: 25}}>
            <ListItemIcon>
              <PrintIcon />
            </ListItemIcon>
            <ListItemText primary="Print Tunggakan" />
          </ListItem>
        }>
        <ExcelSheet data={data} name="Tunggakan_Santri">
          <ExcelColumn 
            label="Nama"
            value={(col) => col.name}/>
          <ExcelColumn 
            label="Nominal"
            value={(col) => `${fareFormatter(col.value)}`}/>
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
}
