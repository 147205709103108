import { firestore } from "src/config/firebase";
import { makeid } from "src/utils/id";
import _ from 'lodash';

export const setData = async (dataSantri, cartData, user, metode, dibayarkan) => {
  const paymentId = makeid(16)
  const totalAmount = cartData.map((item) => item.amount).reduce((a, b) => a + b, 0)
  const batch = firestore.batch();
  const santriRef = firestore.collection('santri').doc(dataSantri.noRekening)
  const historyData = {
        createAt: new Date(),
        id: paymentId,
        name: cartData.map((item) => ` ${item.name}`).toString(),
        paymentCode: 'manual',
        status: 'done',
        totalAmount: parseInt(totalAmount),
        updateAt: new Date(),
        userData: dataSantri,
        paymentID: cartData.map((item) => item.paymentId),
        isManual: true,
        paymentDataId: cartData,
        uangMasuk: parseInt(dibayarkan),
        tellerData: user,
        metode: metode
      }
  try {
    batch.set(santriRef.collection('paymentHistory').doc(paymentId), historyData, { merge: true })
    batch.set(firestore.collection('paymentHistory').doc(paymentId), historyData, { merge: true })
    cartData.forEach(item => {
        batch.set(
            santriRef.collection('payment').doc(item.paymentId)
              .collection('list-payment').doc(item.id),
            item, { merge: true }
          )
      });
    batch.commit()
    console.log(historyData)
  } catch (err) {
    console.log(err);
    alert(err);
  }
}