/* eslint-disable no-shadow */
import { functions } from '../config/firebase';

const initState = {
};

export default {
  state: initState,
  reducers: {
    reset: () => ({ ...initState }),
  },
  effects: (dispatch) => ({
    createPayment: async (data) => {
      try {
        await functions.httpsCallable("createPayment")(data);
        console.log('Sukses')
      } catch (error) {
        console.log(error);
      }
    },
  })
};
