import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { firestore } from 'src/config/firebase';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { fareFormatter } from 'src/utils/fareFormatter';
import firebase from 'firebase'

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  }
}));


export default function FormDialog({ open, handleClose, data, paymentData, dataSantri }) {
  const classes = useStyles();
  const user = useSelector(state => state.user.profile);
  const [value, setValue] = useState(data === null ? '' : data.value)

  const handleSave = async () => {
    const batch = firestore.batch();
    const date = moment().format('DD-MM-YYYY-HH-mm-ss')
    const paymentRef = firestore.doc(`santri/${dataSantri.noRekening}/payment/${paymentData.id}/list-payment/${data.id}`);
    const valueData = {
      value: data.multiple ? firebase.firestore.FieldValue.arrayUnion(parseInt(value)) : parseInt(value),
      isEdited: true,
    };
    const updateData = {
      createAt: new Date(),
      previewsData: data,
      tellerData: user
    }
    try {
      batch.set(paymentRef, valueData, { merge: true });
      batch.set(paymentRef.collection('update').doc(`${date}`), updateData, { merge: true });
      batch.commit();
      handleClose()
    } catch (error) {
      alert(error);
      console.log((error))
    }
  }

  if (data === null) {
    return (
      <div/>
    )
  }

  return (
    <div>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth>
        <DialogTitle className={classes.title} id="form-dialog-title">
          {`Edit Pembayaran ${data.name}`}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="value"
            label="Nominal Pembayaran"
            type="number"
            fullWidth
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Typography>
            {fareFormatter(value)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Batal
          </Button>
          <Button variant="contained" onClick={handleSave} color="primary">
            Simpan
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
