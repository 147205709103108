import React, { useState, useEffect} from 'react'
import Paper from '@material-ui/core/Paper';
import {
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { firestore } from 'src/config/firebase';
import { CircularProgress } from '@material-ui/core';

import RincianTunggakan from './Dialog';

const ListSantri = ({ rowData }) => {
  const [itemData, setItemData] = useState(null);

  useEffect(() => {
    getDataSantri()
  }, [])

  const getDataSantri = async () => {
    try {
      const data = (
        await firestore.collection('payment').doc(`${rowData.id}`)
          .collection('arrears').get()
      ).docs.map((item) => item.data())
      setItemData(data)
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }

  const columns = [
    {
      name: 'id',
      title: 'ID Santri',
      getCellValue: (row) => row.noRekening
    },
    {
      name: 'nameSantri',
      title: 'Nama Santri',
      getCellValue: (row) => row.name.toUpperCase()
    },
    {
      name: 'kamar',
      title: 'Kamar Santri',
      getCellValue: (row) => row.kamar.toUpperCase()
    },
    {
      name: 'sekolah',
      title: 'Sekolah',
      getCellValue: (row) => row.sekolah.toUpperCase()
    },
    {
      name: 'sekolaha',
      title: 'Sekolah',
      getCellValue: (row) => <RincianTunggakan dataSantri={row} paymentData={rowData} />
    },
  ];
  if (itemData === null) {
    return (
      <CircularProgress />
    )
  }

  return (
    <Paper>
      <Grid
        rows={itemData}
        columns={columns}
      >
        <SearchState />
        <IntegratedFiltering />
        <Table />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel />
      </Grid>
    </Paper>
  )
}

export default ListSantri
