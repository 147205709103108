import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { firestore } from 'src/config/firebase';
import { Backdrop, IconButton, CircularProgress, DialogContentText } from '@material-ui/core';
import 'moment/locale/id';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Table from './table';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function FormDialog({ data }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [value, setValue] = useState(null);

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      firestore.collection('payment').doc(`${data.id}`).collection('list-payment')
        .orderBy('createAt', 'desc')
        .onSnapshot((snapshot) => {
          const listData = snapshot.docs.map((item) => {
            let data;
            data = item.data();
            data.id = item.id;
            return data;
          });
          setValue(listData)
        });
    } catch (err) {
      console.log(err);
      alert(ErrorEvent)
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await firestore.collection('payment').doc(`${data.id}`)
        .delete();
      setLoading(false);
    } catch (error) {
      alert(error);
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div>
      <IconButton style={{ marginRight: 10 }} color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <IconButton color="primary" onClick={() => setConfirm(true)}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
        fullWidth>
        <DialogTitle id="form-dialog-title">Tambah Pembayaran</DialogTitle>
        <DialogContent>
          <Table data={value} payData={data}/>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary">
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirm}
        onClose={() => setConfirm(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth>
        <DialogTitle id="form-dialog-title">Konfirmasi</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Apakah Anda Yakin Ingin Menghapus Pembayaran ${data.name === undefined ? '' : data.name.toUpperCase()} ? `}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setConfirm(false)} color="secondary">
            Batal
          </Button>
          <Button variant="contained" onClick={handleDelete} color="primary">
            Konfirmasi
          </Button>
        </DialogActions>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </div>
  );
}

export default FormDialog;
