/* eslint-disable object-curly-newline */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  Button,
  ListItem
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import { connect } from 'react-redux';

import NavItem from './NavItem';
import NavbarCollapse from './collapseList'
import { items } from './items';
import { versionCode } from 'src/config/version';

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  title: {
    marginRight: 'auto'
  },
}));

const NavBar = ({ onMobileClose, openMobile, signOut, user }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const Content = () => {
    if (user === null) {
      return (
        <div />
      )
    }
    return (
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          p={2}
        >
          <Avatar
            className={classes.avatar}
            src={user.photo}
            // to="/app/account"
          />
          <Typography
            className={classes.name}
            color="textPrimary"
            variant="h5"
          >
            {user.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {user.phone}
          </Typography>
        </Box>
        <Divider />
        <Box p={2}>
          <List>
            {items.map((item) => (
              item.sub !== undefined
                ? <NavbarCollapse
                    sub={item.sub}
                    key={item.title}
                    title={item.title}
                    icon={item.icon}
                  />
                : <NavItem
                    href={item.href}
                    key={item.title}
                    title={item.title}
                    icon={item.icon}
                  />
            ))}
            <Hidden lgUp>
              <ListItem
                className={clsx(classes.item)}
                disableGutters
              >
                <Button
                  // activeClassName={classes.active}
                  className={classes.button}
                  onClick={signOut}
                >
                  <InputIcon className={classes.icon} />
                  <span className={classes.title}>
                    LogOut
                  </span>
                </Button>
              </ListItem>
            </Hidden>
          </List>
        </Box>
        <Box flexGrow={1} />
        <Typography variant="body2" gutterBottom style={{textAlign: 'center', color: 'grey'}}>
          Version: {versionCode}
        </Typography>
      </Box>
    )
  }
  if (user === null) {
    return (
      <div />
    )
  }
  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Content />
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          <Content />
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  signOut: PropTypes.func,
  user: PropTypes.object
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
  signOut: () => {},
  user: {}
};
const mapState = (state) => ({
  user: state.user.profile
});

export default connect(mapState)(NavBar);
