/* eslint-disable react/no-array-index-key */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable no-else-return */
import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grids from '@material-ui/core/Grid';
import {
  SearchState,
  IntegratedFiltering,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  makeStyles, CircularProgress, IconButton
} from '@material-ui/core';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';

import Page from 'src/components/Page';
import { fareFormatter } from 'src/utils/fareFormatter';
// import { firestore } from 'src/config/firebase';

import { firestore } from 'src/config/firebase';
import AddItem from './inputPayment';
import AddSantri from './SantriAddPayment';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
}));

const ValueColumn = ({ value }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
      {
        value.map((item, idx) => {
          return (<div key={idx}>{`${fareFormatter(item)}; `}</div>);
        })
      }
    </div>
  );
};

function Index({ data, payData }) {
  const classes = useStyles();

  const deleteItem = async (id) => {
    const ref = firestore.doc(`payment/${payData.id}/list-payment/${id}`);
    try {
      ref.delete();
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const columns = [
    {
      name: 'createAt',
      title: 'Dibuat Pada',
      getCellValue: (row) => moment(row.createAt.toDate()).format('DD MM YYYY')
    },
    {
      name: 'name',
      title: 'Nama',
      getCellValue: (row) => row.name.toUpperCase()
    },
    {
      name: 'value',
      title: 'Nominal',
      getCellValue: (row) => !row.multiple
                                ? fareFormatter(row.value)
                                : <ValueColumn value={row.value} />
    },
    {
      name: 'addSantri',
      title: 'Add Santri',
      getCellValue: (row) =>  <AddSantri payData={row} data={payData} />
    },
    {
      name: 'hapus',
      title: 'Hapus',
      getCellValue: (row) =>  <IconButton color="primary" aria-label="upload picture" onClick={() => deleteItem(row.id)}>
                                <DeleteIcon />
                              </IconButton>
    },
  ];

  if (data === undefined) {
    return (
      <Page
        className={classes.loading}
        title="Pembayaran"
      >
        <CircularProgress color="primary" />
      </Page>
    );
  } else {
    return (
      <Page
        className={classes.root}
      >
        <Grids container justifyContent="space-around" style={{ marginBottom: 15 }}>
          <AddItem payData={payData} />
          {/* <AddSantri payData={payData} /> */}
        </Grids>
        <Paper>
          <Grid
            rows={data}
            columns={columns}
          >
            <SearchState />
            <RowDetailState />
            <IntegratedFiltering />
            <Table />
            <TableHeaderRow />
            <Toolbar />
            <SearchPanel />
          </Grid>
        </Paper>
      </Page>
    );
  }
}

export default Index;
