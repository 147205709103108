// import Firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/firestore';
// import 'firebase/storage';
// import 'firebase/database';
// import 'firebase/functions';

// const config = {
//   apiKey: "AIzaSyCW-UwGpsQzn51TrC98PRoKQ6IZ1DCoaEg",
//   authDomain: "almuhibin-e8961.firebaseapp.com",
//   databaseURL: "https://almuhibin-e8961.firebaseio.com",
//   projectId: "almuhibin-e8961",
//   storageBucket: "almuhibin-e8961.appspot.com",
//   messagingSenderId: "547283040008",
//   appId: "1:547283040008:web:65c2ffc0c0f18e952a1ebd",
//   measurementId: "G-53DGH62MSN"
// };

// if (!Firebase.apps.length) Firebase.initializeApp(config);

// const firebase = Firebase;
// const auth = Firebase.auth();
// const firestore = Firebase.firestore();
// const storage = Firebase.storage();
// const database = Firebase.database();

// if (process.env.NODE_ENV === 'development') {
//   // Firebase.functions().useFunctionsEmulator('http://localhost:5001')
// }

// const functions = Firebase.app().functions('asia-southeast2');

// export {
//   firestore, auth, storage, database, functions
// };
// export default firebase;

import Firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/functions';

const config = {
  apiKey: 'AIzaSyCHWIuCPaNj_q1VAxjiKn46qldfUeeLhNU',
  authDomain: 'almuhibbin-76834.firebaseapp.com',
  databaseURL: 'https://almuhibbin-76834.firebaseio.com',
  projectId: 'almuhibbin-76834',
  storageBucket: 'almuhibbin-76834.appspot.com',
  messagingSenderId: '881497627200',
  appId: '1:881497627200:web:fd3ed39366fccba90a9da5',
  measurementId: 'G-G2J1NXEF70'
};

if (!Firebase.apps.length) Firebase.initializeApp(config);

const firebase = Firebase;
const auth = Firebase.auth();
const firestore = Firebase.firestore();
const storage = Firebase.storage();
const database = Firebase.database();

if (process.env.NODE_ENV === 'development') {
  // Firebase.functions().useFunctionsEmulator('http://localhost:5001')
}

const functions = Firebase.app().functions('asia-southeast2');

export {
  firestore, auth, storage, database, functions
};
export default firebase;
