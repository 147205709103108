import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { firestore } from 'src/config/firebase';
import 'moment/locale/id';
import { green } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
    position: 'absolute',
    bottom: theme.spacing(5),
    right: theme.spacing(3),
  },
}));

function FormDialog({ isAdmin }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [date, setDate] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setName('');
    setDate('');
  }, []);

  const handleSavePayments = async () => {
    const paymentRef = firestore.collection('payment')
    const setData = {
      category: {
        id: 1,
        name: 'Bulanan'
      },
      createAt: new Date(),
      date: date,
      name: name
    };
    try {
      await paymentRef.add(setData);
      alert('Data Berhasil Tersimpan');
      handleClose();
    } catch (error) {
      alert(error)
      console.log(error);
    }
  };
  if (!isAdmin) {
    return null
  }
  return (
    <div>
      <Fab className={classes.fabGreen} color="primary" aria-label="add" onClick={handleClickOpen}>
        <AddIcon />
      </Fab>
      {/* <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Tambah Pembayaran
      </Button> */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">Tambah Pembayaran</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nama"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="date"
            label="Keterangan"
            fullWidth
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Batal
          </Button>
          <Button onClick={handleSavePayments} color="primary">
            Tambah
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialog;
