import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';

import Dettail from './Dettail';

export default function FormDialog({ data, open, handleClose, setOpen }) {

  return (
    <div>
      {/* <ListItem autoFocus={false} button onClick={() => setOpen(true)}>
        <ListItemIcon>
          <AddToHomeScreenIcon />
        </ListItemIcon>
        <ListItemText primary="Tambah Saldo" />
      </ListItem> */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Tambah Saldo Tabungan</DialogTitle>
        <DialogContent>
          <Dettail data={data} setOpen={setOpen} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
