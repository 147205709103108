import React from 'react';
import TextField from '@material-ui/core/TextField';
import { fareFormatter } from 'src/utils/fareFormatter';
import {
  Typography,
  Grid,
  Button,
  CardHeader
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import 'moment/locale/id';
import moment from 'moment';

import PrintButton from './printButton';
import OnVerificationDettail from './components/dettailRow/onVerification';
import RejectDettail from './components/dettailRow/rejectDettail';

const RincianTabungan = ({ data, classes }) => {
  return (
    <Grid container justifyContent="space-around" spacing={3}>
      <Grid item xs={6}>
        <TextField
          id="name"
          label="Jenis Pembayaran"
          value={data.name}
          contentEditable={false}
          fullWidth
          margin="dense"
        />
        <TextField
          id="type"
          label="Tipe"
          value={data.type.toUpperCase()}
          contentEditable={false}
          fullWidth
          margin="dense"
        />
        {
          data.keterangan === undefined
            ? null
            : <TextField
                id="keterangan"
                label="Keterangan"
                value={data.keterangan}
                contentEditable={false}
                fullWidth
                margin="dense"
              />
        }
        <TextField
          id="totalAmount"
          label="Total Pembayaran"
          value={fareFormatter(data.totalAmount)}
          contentEditable={false}
          variant="outlined"
          margin="normal"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        {
          data.bill === undefined || data.bill === null
            ? <div />
            : <Card style={{ maxWidth: 345 }}>
                <CardHeader
                  title="Bukti Pembayaran"
                  subheader={`Di Upload Pada ${moment(data.confirmAt.toDate()).format('DD MMM YYYY')}`}
                />
                <CardMedia
                  className={classes.media}
                  image={data.bill}
                  title={data.billId}
                />
                <CardActions disableSpacing>
                  <Button href={data.bill} target="_blank">
                    Lihat
                  </Button>
                </CardActions>
              </Card>
        }
      </Grid>
    </Grid>
  );
};

const RincianPembayaran = ({ data, ...props }) => {
  // console.log(data);
  return (
      <TextField
        label={data === null ? 'Memuat' : data.name.toUpperCase()}
        value={data === null ? 'Memuat' : fareFormatter(data.amount)}
        contentEditable={false}
        fullWidth
        margin="dense"
      />
  );
};

const useStyles = makeStyles(() => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  }
}));

function RowDettail({ row }) {
  const classes = useStyles();

  if (row.status === 'done') {
    return (
      <div>
        <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold' }}>
          Detail Pembayaran
        </Typography>
        {
          row.paymentDataId !== undefined
            ? <Grid container justifyContent="space-around" spacing={3}>
                <Grid item xs={6}>
                  {
                    row.paymentDataId.map((item) => {
                      return (
                        <RincianPembayaran key={item.id} data={item} />
                      );
                    })
                  }
                  {
                    row.saku === null || row.saku === undefined
                      ? null
                      : <RincianPembayaran data={{name: row.saku.name, amount: row.saku.totalAmount}} />
                  }
                  {
                    row.tabungan === null || row.saku === undefined
                      ? null
                      : <RincianPembayaran data={{name: row.tabungan.name, amount: row.tabungan.totalAmount}} />
                  }
                  <TextField
                    label="Total Pembayaran"
                    value={fareFormatter(row.totalAmount)}
                    contentEditable={false}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  {
                    row.bill === undefined || row.bill === null
                      ? <div />
                      : <Card style={{ maxWidth: 345 }}>
                          <CardHeader
                            title="Bukti Pembayaran"
                            subheader={moment(row.confirmAt.toDate()).format('DD MMM YYYY')}
                          />
                          <CardMedia
                            className={classes.media}
                            image={row.bill}
                            title={row.billId}
                          />
                          <CardActions disableSpacing>
                            <Button href={row.bill} target="_blank">
                              Lihat
                            </Button>
                          </CardActions>
                        </Card>
                  }
                </Grid>
              </Grid>
            : <RincianTabungan data={row} classes={classes} />
        }
        {
          row.tellerData === undefined
            ? null
            : <Typography>
                {`Petugas: ${row.tellerData.name}`}
              </Typography>
        }
        {
          row.metode === undefined
            ? null
            : <Typography>
                {`Metode Pembayaran: ${row.metode}`}
              </Typography>
        }
      <PrintButton data={row} />
      </div>
    );
  } else if (row.status === 'pending') {
    return (
      <div>
        <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold' }}>
          Detail Pembayaran
        </Typography>
        {
          row.paymentDataId !== undefined
            ? <Grid container justifyContent="space-around" spacing={3}>
                <Grid item xs={6}>
                  {
                    row.paymentDataId.map((item) => {
                      return (
                        <RincianPembayaran key={item.id} data={item} />
                      );
                    })
                  }
                  {
                    row.saku === null || row.saku === undefined
                      ? null
                      : <RincianPembayaran data={{name: row.saku.name, amount: row.saku.totalAmount}} />
                  }
                  {
                    row.tabungan === null || row.tabungan === undefined
                      ? null
                      : <RincianPembayaran data={{name: row.tabungan.name, amount: row.tabungan.totalAmount}} />
                  }
                  <TextField
                    label="Total Pembayaran"
                    value={fareFormatter(row.totalAmount)}
                    contentEditable={false}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  {
                    row.bill === undefined || row.bill === null
                      ? <div />
                      : <Card style={{ maxWidth: 345 }}>
                          <CardHeader
                            title="Bukti Pembayaran"
                            subheader={moment(row.confirmAt.toDate()).format('DD MMM YYYY')}
                          />
                          <CardMedia
                            className={classes.media}
                            image={row.bill}
                            title={row.billId}
                          />
                          <CardActions disableSpacing>
                            <Button href={row.bill} target="_blank">
                              Lihat
                            </Button>
                          </CardActions>
                        </Card>
                  }
                </Grid>
              </Grid>
            : <RincianTabungan data={row} classes={classes} />
        }
      </div>
    )
  } else if (row.status === 'onVerification') {
    return (
      <OnVerificationDettail row={row} />
    )
  } else if (row.status === 'reject') {
    return (
      <RejectDettail row={row} />
    )
  } else {
    return (
      <div>
        oops maybe any problems happen!
      </div>
    )
  }
}

export default RowDettail;
