import React from 'react';
import Switch from '@material-ui/core/Switch';
import { firestore } from '../../../../config/firebase';
import { Button } from '@material-ui/core';

export default function Switches({ data, user }) {

  const handleDelete = async (event) => {
    if (user.isSuperAuthority) {
      try {
        await firestore.doc(`admin/${data.id}`)
          .delete()
      } catch (error) {
        alert(error);
        console.log(error);
      }
    } else {
      alert('Akses Ditolak !')
    }
  };

  return (
      <div>
        <Button variant="contained" color="secondary" onClick={handleDelete} >
          Hapus
        </Button>
      </div>
  );
}
