import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { firestore } from 'src/config/firebase';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import firebase from 'firebase';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(0.5),
  },
}));

function AcceptButton({ data }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteReset, setDeleteReset] = useState(false);
  const [santri, setSantri] = useState(null);

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      firestore.collection('santri').doc(`${data.userData.noRekening}`)
        .onSnapshot((snapshot) => {
          setSantri(snapshot.data())
        });
    } catch (err) {
      console.log(err);
      alert(ErrorEvent)
    }
  }

  const deleteAndResetPayment = async () => {
    setLoading(true);
    const batch = firestore.batch();
    // ref
    const paymentRef = firestore.collection('paymentHistory').doc(`${data.id}`);
    const santriHistoryPaymentRef = firestore.doc(`santri/${data.userData.noRekening}/paymentHistory/${data.id}`);
    const santriRef = firestore.collection('santri').doc(`${data.userData.noRekening}`);
    const santriTabunganRef = santriRef.collection('tabunganHistory').doc(`${data.id}`);
    const tabunganHistoryRef = firestore.collection('tabunganHistory').doc(`${data.id}`);
    const santriSakuRef = santriRef.collection('sakuHistory').doc(`${data.id}`);
    const sakuHistoryRef = firestore.collection('sakuHistory').doc(`${data.id}`);
    try {
      if (!Number.isNaN(data.totalAmount)) {
        if (data.paymentDataId !== undefined) {
          if (_.isArray(data.paymentID)) {
            console.log('array cuk');
            batch.delete(paymentRef);
            batch.delete(santriHistoryPaymentRef);
            data.paymentDataId.forEach((a) => {
              batch.set(
                  santriRef.collection('payment').doc(`${a.paymentId}`).collection('list-payment').doc(`${a.id}`),
                  {
                    amount: 0,
                    values: a.values - a.amount
                  }, { merge: true }
                )
            });
          } else {
            data.paymentDataId.forEach((a) => {
              batch.delete(paymentRef);
              batch.delete(santriHistoryPaymentRef);
              batch.set(
                  santriRef.collection('payment').doc(`${data.paymentID}`).collection('list-payment').doc(`${a.id}`),
                  {
                    amount: 0,
                    values: a.values - a.amount
                  }, { merge: true }
                )
            });
            console.log('duduk array')
          }
        } else if (data.isTabungan) {
          if (data.type === 'Masuk') {
            if (santri.tabungan.value - data.totalAmount < 0) {
              alert('Saldo Lebih Kecil Dari pada Nominal');
            } else {
              batch.delete(paymentRef);
              batch.delete(santriHistoryPaymentRef);
              batch.delete(santriTabunganRef);
              batch.delete(tabunganHistoryRef);
              batch.set(santriRef, {
                tabungan: {
                  value: data.type === 'Keluar' ? firebase.firestore.FieldValue.increment(data.totalAmount) : firebase.firestore.FieldValue.increment(-data.totalAmount),
                  updateAt: new Date()
                }
              }, { merge: true });
            }
          } else {
            batch.delete(paymentRef);
            batch.delete(santriHistoryPaymentRef);
            batch.delete(santriTabunganRef);
            batch.delete(tabunganHistoryRef);
            batch.set(santriRef, {
              tabungan: {
                value: data.type === 'Keluar' ? firebase.firestore.FieldValue.increment(data.totalAmount) : firebase.firestore.FieldValue.increment(-data.totalAmount),
                updateAt: new Date()
              }
            }, { merge: true });
          }
        } else if (data.isSaku) {
          if (data.type === 'Masuk') {
            if (santri.saku.value - data.totalAmount < 0) {
              alert('Saldo Lebih Kecil Dari pada Nominal');
            } else {
              batch.delete(paymentRef);
              batch.delete(santriHistoryPaymentRef);
              batch.delete(santriSakuRef);
              batch.delete(sakuHistoryRef);
              batch.set(santriRef, {
                saku: {
                  value: data.type === 'Keluar' ? firebase.firestore.FieldValue.increment(data.totalAmount) : firebase.firestore.FieldValue.increment(-data.totalAmount),
                  updateAt: new Date()
                }
              }, { merge: true });
            }
          } else {
            batch.delete(paymentRef);
            batch.delete(santriHistoryPaymentRef);
            batch.delete(santriSakuRef);
            batch.delete(sakuHistoryRef);
            batch.set(santriRef, {
              saku: {
                value: data.type === 'Keluar' ? firebase.firestore.FieldValue.increment(data.totalAmount) : firebase.firestore.FieldValue.increment(-data.totalAmount),
                updateAt: new Date()
              }
            }, { merge: true });
          }
        }
      }
      batch.commit();
      setDeleteReset(false);
      setLoading(false);
      setAnchorEl(null);
    } catch (error) {
      console.log(error);
      alert(error);
      setLoading(false);
      setAnchorEl(null);
    }
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className={classes.root}>
      <IconButton color="primary" aria-label="upload picture" onClick={handleClick}>
        <AccountBalanceWalletIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => setDeleteReset(true)}>Hapus Dan Reset Pembayaran</MenuItem>
      </Menu>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={deleteReset}
        onClose={() => setDeleteReset(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Konfirmasi Diperlukan</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah Anda Yakin Ingin Menghapus dan Mereset Data Transaksi Dari {`${data.userData.name.toUpperCase()} ?`}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setDeleteReset(false)} color="secondary">
            Batal
          </Button>
          <Button variant="contained" onClick={deleteAndResetPayment} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AcceptButton.propTypes = {
  data: PropTypes.object
};

export default AcceptButton;
