import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Typography, TextField } from '@material-ui/core';
import { fareFormatter } from 'src/utils/fareFormatter';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClose, transData, userData }) {

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle>Transaksi Berhasil</DialogTitle>
      <DialogContent>
        {
          transData && <TextField 
                          label='Nominal Uang Masuk'
                          value={fareFormatter(transData.totalAmount)}
                          contentEditable={false}
                          fullWidth
                          margin='normal'
                        />
        }
        <TextField 
          label='Saldo Terkini'
          value={fareFormatter(userData.saku.value)}
          contentEditable={false}
          fullWidth
          margin='normal'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
