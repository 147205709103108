import 'date-fns';
import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  RowDetailState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  PagingPanel,
  TableRowDetail,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import {
  makeStyles, CircularProgress, LinearProgress
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import moment from 'moment';
import Grids from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Page from 'src/components/Page';
import { firestore } from 'src/config/firebase';
import { fareFormatter } from 'src/utils/fareFormatter';
import RowDetail from '../payment/rowDettail';
import Excel from './printButton';
import DeleteButton from '../payment/components/deleteButton';
import PendingDelete from '../payment/components/action/pending/deleteDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(1)
  },
  productCard: {
    height: '100%'
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Index() {
  const classes = useStyles();
  const user = useSelector(state => state.user.profile);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizes] = useState([5, 10, 25, 50, 100, 250, 500, 1000, 0]);
  const [startDate, setStartDate] = useState(moment().startOf('day'));
  const [endDate, setEndDate] = useState(moment().endOf('day'));
  const [rows, setRows] = useState(null);
  const [loading, setLoading] = useState(false);
  const [defaultHiddenColumnNames] = useState(['action']);

  useEffect(() => {
    getDatafromDB()
  }, [startDate, endDate])

  const getDatafromDB = async () => {
    setLoading(true)
    try {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        await firestore.collection('sakuHistory')
          .where('createAt', '>=', new Date(startDate.valueOf()))
          .where('createAt', '<=', new Date(endDate.valueOf()))
          .limit(5)
          .orderBy('createAt', 'desc')
          .onSnapshot(function(doc) {
              const getData = doc.docs.map((item) => {
                                  var a;
                                  a = item.data();
                                  a.id = item.id;
                                  return a;
                                });
              setRows(getData);
              setLoading(false);
          });
      } else {
        await firestore.collection('sakuHistory')
          .where('createAt', '>=', new Date(startDate.valueOf()))
          .where('createAt', '<=', new Date(endDate.valueOf()))
          .orderBy('createAt', 'desc')
          .onSnapshot(function(doc) {
              const getData = doc.docs.map((item) => {
                                  var a;
                                  a = item.data();
                                  a.id = item.id;
                                  return a;
                                });
              setRows(getData);
              setLoading(false);
          });
      }
      
    } catch (err) {
      alert(err);
      console.log(err);
      setLoading(false);
    }
  }
  
  const columns = [
    {
      name: 'createAt',
      title: 'Tanggal Pembayaran',
      getCellValue: (row) => moment(row.createAt.toDate()).format('DD MMM YYYY, HH:mm:ss')
    },
    {
      name: 'id',
      title: 'ID Transaksi',
      getCellValue: (row) => row.id
    },
    {
      name: 'noRekening',
      title: 'No.Rekening',
      getCellValue: (row) => row.userData.noRekening
    },
    {
      name: 'name',
      title: 'Nama Santri',
      getCellValue: (row) => row.userData.name.toUpperCase()
    },
    {
      name: 'type',
      title: 'Type',
      getCellValue: (row) => row.type
    },
    {
      name: 'metode',
      title: 'Metode',
      getCellValue: (row) => row.method === undefined ? ' - ' : row.method
    },
    {
      name: 'total',
      title: 'Nominal',
      getCellValue: (row) => fareFormatter(row.totalAmount)
    },
    {
      name: 'status',
      title: 'Status',
      getCellValue: (row) => row.status === 'pending'
                              ? row.status.toUpperCase()
                              : row.status
    },
    {
      name: 'action',
      title: 'Tindakan',
      getCellValue: (row) => row.status === 'done'
                              ? <DeleteButton data={row} />
                              : row.status === 'pending'
                                ? <PendingDelete row={row} />
                                : <div />
    },
  ];

  const excelData = rows === null
                      ? []
                      : rows.filter((e) => e.status === 'done')

  if (rows === null) {
    return (
      <Page
        className={classes.loading}
        title="Pembayaran"
      >
        <CircularProgress color="primary" />
      </Page>
    );
  } else if (user === null) {
    return (
      <div />
    )
  } else {
    return (
      <Page
        className={classes.root}
        title="Pembayaran"
      >
        <Paper>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grids container justifyContent="space-around">
              <KeyboardDatePicker
                margin="normal"
                label="Mulai Tanggal"
                format="DD/MM/YYYY"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                margin="normal"
                label="Sampai Tanggal"
                format="DD/MM/YYYY"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              {
                user.authority.laporan
                  ? <Excel data={excelData} />
                  : <div />
              }
            </Grids>
          </MuiPickersUtilsProvider>
          { loading && <LinearProgress /> }
          <Grid
            rows={rows}
            columns={columns}
          >
            <SearchState />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <RowDetailState />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow />
            {
              user.id === 'BMA01'
                ? null
                : <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                  />
            }
            <Toolbar />
            <PagingPanel
              pageSizes={pageSizes}
            />
            <SearchPanel />
            <TableRowDetail
              contentComponent={RowDetail}
            />
          </Grid>
        </Paper>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Page>
    );
  }
}

export default Index;
