import 'date-fns';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import HistoryTable from './historyTable';

function Index({ santriData, open, handleClose }) {

  return (
    <>
      <Dialog
        keepMounted={false}
        open={open}
        maxWidth="lg"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Riwayat Tabungan ${santriData.name}`}</DialogTitle>
        <DialogContent>
          <HistoryTable santriData={santriData} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary" autoFocus>
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
    
    </>
  );
}

export default Index;
