import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  PagingPanel,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  makeStyles, CircularProgress
} from '@material-ui/core';

import Page from 'src/components/Page';
import CheckBox from './inputSantriButton';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
}));

function Index({ payData, data }) {
  const classes = useStyles();
  const santri = useSelector(state => state.santri.santri);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizes] = useState([5, 10, 25, 50, 100, 250, 500, 1000, 0]);

  const columns = [
    {
      name: 'id',
      title: 'ID Santri',
      getCellValue: (row) => row.noRekening
    },
    {
      name: 'nameSantri',
      title: 'Nama Santri',
      getCellValue: (row) => row.name.toUpperCase()
    },
    {
      name: 'alamat',
      title: 'Alamat Santri',
      getCellValue: (row) => row.alamat.toUpperCase()
    },
    {
      name: 'kamar',
      title: 'Kamar Santri',
      getCellValue: (row) => row.kamar.toUpperCase()
    },
    {
      name: 'sekolah',
      title: 'Sekolah',
      getCellValue: (row) => row.sekolah.toUpperCase()
    },
    {
      name: 'view',
      title: 'View',
      getCellValue: (row) => <CheckBox data={data} dataSantri={row} paymentData={payData} />
    },
  ];

  if (santri === null) {
    return (
      <Page
        className={classes.loading}
        title="Pembayaran"
      >
        <CircularProgress color="primary" />
      </Page>
    );
  } else {
    return (
      <Paper>
        <Grid
          rows={santri.santri}
          columns={columns}
        >
          <SearchState />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <RowDetailState />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table />
          <VirtualTable />
          <TableHeaderRow />
          <Toolbar />
          <PagingPanel
            pageSizes={pageSizes}
          />
          <SearchPanel />
        </Grid>
      </Paper>
    );
  }
}

export default Index;
