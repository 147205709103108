import React, { useState, useEffect} from 'react'
import Paper from '@material-ui/core/Paper';
import {
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { firestore } from 'src/config/firebase';
import { CircularProgress } from '@material-ui/core';

import RincianTunggakan from './Dialog';

const ListSantri = ({ rowData }) => {
  const [itemData, setItemData] = useState(null);

  useEffect(() => {
    getDataSantri([])
  }, [])

  const getDataSantri = async () => {
    try {
      const data = (
        await firestore.collection('payment').doc(`${rowData.id}`)
          .collection('arrears').get()
      ).size
      setItemData(data)
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }
  if (itemData === null) {
    return (
      <CircularProgress />
    )
  }

  return (
    <div>
      {`${itemData} Orang`}
    </div>
  )
}

export default ListSantri
