import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, fade } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField'

import CardMedia from './cardItem';
import { firestore } from 'src/config/firebase';
import { CircularProgress, Button } from '@material-ui/core';
import AddDialog from './add';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    backgroundColor: 'white'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function FullWidthGrid() {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [data, setData] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
      try {
        await firestore.collection('config').doc('imageSlider')
                .collection('images')
                .onSnapshot((querySnapshot) => {
                    const snapshot = querySnapshot.docs.map((e) => {
                            var val;
                            val = e.data();
                            val.id = e.id;
                            return val
                        })
                    setData(snapshot)
                    setRowData(snapshot)
                });
      } catch (err) {
        console.log(err);
      }
    }


  const handleChangeSearch = (e) => {
    const str = e.target.value
    // const searching = dummyArray.filter((val) => val.title.toUpperCase() == str.toUpperCase())
    setValue(str)
    // setData(searching)
  }

  console.log(rowData)

  if (rowData === null) {
    return (
      <div style={{display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {
          data.map((e, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={`list-${index}`}>
                <CardMedia item={e} />
              </Grid>
            )
          })
        }
      </Grid>
      <Fab variant="extended" className={classes.fab} onClick={() => setOpen(true)} >
        <AddPhotoAlternateIcon />
        Tambah Foto
      </Fab>
      <AddDialog open={open} handleClose={() => setOpen(false)} />
    </div>
  );
}
