import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  PagingState,
  IntegratedPaging,
  RowDetailState,
  SearchState,
  IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  TableHeaderRow,
  PagingPanel,
  TableColumnVisibility,
  SearchPanel
} from '@devexpress/dx-react-grid-material-ui';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';

import { firestore } from 'src/config/firebase';
import { columns } from './column';
import Page from 'src/components/Page';
import Sumary from './components/total';

function Index() {
  const user = useSelector(state => state.user.profile);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizes] = useState([5, 10, 25, 50, 100, 250, 500, 1000, 0]);
  const [defaultHiddenColumnNames] = useState(['tambah', 'withdraw']);
  const [rows, setRows] = useState(null);

  const getData = async () => {
    try {
      if (user.authority.tabungan) {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          await firestore.collection('santriDebug')
            // .limit(10)
            // .orderBy('name', 'asc')
            .where('noRekening', '==', '02-010038')
            .onSnapshot((snap) => {
              const dataArr = snap.docs.map((e) => {
                var a;
                a = e.data();
                a.id = e.id;
                return a;
              })
              setRows(dataArr)
            })
        } else {
          await firestore.collection('santri')
            .orderBy('name', 'asc')
            .onSnapshot((snap) => {
              const dataArr = snap.docs.map((e) => {
                var a;
                a = e.data();
                a.id = e.id;
                return a;
              })
              setRows(dataArr)
            })
        }
      }
      setRows([]);
    } catch (err) {
      console.log(err);
      alert('Tidak Dapat membaca Data Santri')
    }
  }

  useEffect(() => {
    let running = true
    if (running) {
      getData()
    }
    return () => {
      running = false
    }
  }, [])

  if (user === null) {
    return <Paper style={{padding: 10}}>
              <LinearProgress color='secondary' />
            </Paper>
  } else if (rows === null) {
    return <Paper style={{padding: 10}}>
              <LinearProgress color='secondary' />
            </Paper>
  }

    return (
      <Page >
        <Paper style={{margin: 10, padding: 10, paddingTop: 20}}>
          <Grid
            rows={rows}
            columns={columns}
          >
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <RowDetailState />
            <SearchState />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow />
            {
              user.authority.tabungan
                ? null
                : <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
            }
            <Toolbar />
            <SearchPanel />
            <PagingPanel
              pageSizes={pageSizes}
            />
          </Grid>
          <Sumary row={rows} />
        </Paper>
      </Page>
    );
  }

export default Index;
