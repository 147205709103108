import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import 'moment/locale/id';
import { fareFormatter } from 'src/utils/fareFormatter';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { firestore } from 'src/config/firebase';
import _ from 'lodash';

const Name = ({ data, item }) => {
  const [name, setName] = useState('');
  useEffect(() => {
    getName()
  })

  const getName = async () => {
    try {
      if (_.isArray(data.paymentID)) {
        setName(`Pembayaran ${data.userData.name}`)
      } else {
        if (data.paymentID === 'none') {
          setName(`Pembayaran ${data.userData.name}`)
        } else {
          const paymentName = (
            await firestore.collection('payment').doc(`${data.paymentID}`).get()).data().name
          setName(paymentName)
        }
      }
    } catch (err) {
      console.log(`${err} => ${data.id}`)
    }
  }
  return <div style={{textTransform: 'capitalize'}}>{name}</div>
}

const Download = ({ data }) => {
  return (
    <div>
      <label htmlFor="print-excel">
        <Button component="span" fullWidth size="large" variant="contained" color="primary" style={{ marginTop: 20 }}>
          Cetak Laporan
        </Button>
      </label>
      <div style={{display: 'none'}}> 
        <ReactHTMLTableToExcel
          id="print-excel"
          className="download-table-xls-button"
          table="table-to-xls"
          filename={`Laporan-pembayaran-${moment().format('DD-MM-YYYY')}`}
          sheet="tablexls"
          buttonText="Download as XLS" />
      </div>
      <table 
        id="table-to-xls" 
        style={{display: 'none'}}>
        <thead>
          <tr>
            <th rowSpan={2}>Tanggal</th>
            <th rowSpan={2}>Nomor Transaksi</th>
            <th rowSpan={2}>Metode Pembayaran</th>
            <th rowSpan={2}>Petugas</th>
            <th rowSpan={2}>No. Rekening</th>
            <th rowSpan={2}>Nama</th>
            <th rowSpan={2}>Jenis Kelamin</th>
            <th rowSpan={2}>Sekolah</th>
            <th rowSpan={2}>Kamar</th>
            <th rowSpan={2}>Jenis Biaya Siswa</th>
            <th colSpan={2}>Rincian Pembayaran</th>
            <th rowSpan={2}>Total Pembayaran</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Nama Pembayaran
            </td>
            <td>
              Jumlah
            </td>
          </tr>
        {
          data.map((i, b) => {
            return (
              <tr key={b}>
                <td>{moment(i.createAt.toDate()).format('DD/MM/YYYY')}</td>
                <td>{i.id}</td>
                <td>{i.metode}</td>
                <td>{i.tellerData.name}</td>
                <td>{i.userData.noRekening}</td>
                <td>{i.userData.name}</td>
                <td>{i.userData.kelamin}</td>
                <td>{i.userData.sekolah}</td>
                <td>{i.userData.kamar}</td>
                <td>
                  {
                    i.isTabungan || i.isSaku
                      ? `${i.name} - (${i.type})`
                      : <Name data={i} />
                  }
                </td>
                <td>
                  {
                    i.paymentDataId === undefined
                      ? null
                      : i.paymentDataId.length <= 0
                        ? null
                        :  i.paymentDataId.map((c, d) => {
                            return (
                              <div key={d}>{`${c.name}`}</div>
                            )
                          })
                  }
                </td>
                <td>
                  {
                    i.paymentDataId === undefined
                      ? null
                      : i.paymentDataId.map((c, d) => {
                          return (
                            <div key={d}>{`${c.amount === undefined ? '' : fareFormatter(c.amount)}`}</div>
                          )
                        })
                  }
                </td>
                <td>{fareFormatter(i.totalAmount)}</td>
              </tr>
            )
          })
        }
        </tbody>
      </table>

    </div>
  );
};

export default Download;
