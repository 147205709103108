import { fareFormatter } from 'src/utils/fareFormatter';
import MenuList from './components/menu';

export const columns = [
  {
    name: 'nameSantri',
    title: 'Nama Santri',
    getCellValue: (row) => `${row.name === undefined ? ' - ' : row.name}`
  },
  {
    name: 'kamar',
    title: 'Kamar Santri',
    getCellValue: (row) => `${row.kamar === undefined ? ' - ' : row.kamar}`
  },
  {
    name: 'noRekening',
    title: 'Rekening Santri',
    getCellValue: (row) => row.noRekening
  },
  {
    name: 'ime',
    title: 'Nomor IME',
    getCellValue: (row) => row.ime === undefined ? ' - ' : row.ime
  },
  {
    name: 'value',
    title: 'Tabungan',
    getCellValue: (row) => row.tabungan === undefined ? '0' : fareFormatter(row.tabungan.value)
  },
  {
    name: 'menu',
    title: 'Menu',
    getCellValue: (row) => <MenuList data={row} />
  },
];