import React, { useState } from 'react';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { firestore } from 'src/config/firebase';
import { makeid } from 'src/utils/id';
import { useSelector } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import ConfirmationDialog from './confirmation';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
}));

function Dettail({ data, setOpen }) {
  const classes = useStyles();
  const user = useSelector(state => state.user.profile);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('');
  const [method, setMethod] = useState(' - ');
  const [keterangan, setKeterangan] = useState('');
  const [isFinish, setIsFinish] = useState(false);
  const [transData, setTransData] = useState(null);

  const isNumeric = (value) => {
    const reg = /^-?\d+$/.test(value);
    const cpl = value.match(/\d+/gi) === null ? '0' : parseInt(value.match(/\d+/gi).join(''));
    return {
      reg: reg,
      cpl: cpl
    }
  }

  const handleAddSaldo = async () => {
    setIsLoading(true);
    const tabunganId = makeid(12);
    const batch = firestore.batch();
    const santriRef = firestore.collection('santri').doc(`${data.noRekening}`);
    const tabunganHistory = santriRef.collection('tabunganHistory').doc(`${tabunganId}`);
    const tabunganRef = firestore.collection('tabunganHistory').doc(`${tabunganId}`);
    const paymentHistoryRef = firestore.collection('paymentHistory').doc(`${tabunganId}`);
    const addSaldo = isNumeric(value).cpl;
    try {
      if (!isNumeric(value).reg) {
        setIsLoading(false);
        return alert('Input Tidak Valid, Input Nominal Harus Berupa Angka saja dan Tidak Mencantumkan Titik, Koma, Atau Tanda Baca Lain');
      }
      if (Number.isNaN(addSaldo)) {
        setIsLoading(false);
        return alert('Input Tidak Valid, Input Nominal Harus Berupa Angka saja dan Tidak Mencantumkan Titik, Koma, Atau Tanda Baca Lain');
      }
      if (isNumeric(value).cpl === '0') {
        setIsLoading(false);
        return alert('Input Tidak Valid, Input Nominal Harus Berupa Angka saja dan Tidak Mencantumkan Titik, Koma, Atau Tanda Baca Lain');
      }
      if (!isNumeric(value).reg) {
        setIsLoading(false);
        return alert('Input Tidak Valid, Input Nominal Harus Berupa Angka saja dan Tidak Mencantumkan Titik, Koma, Atau Tanda Baca Lain');
      }
      if (!data.tabungan) {
        setIsLoading(false);
        return alert('Tidak Dapat Memuat Info Saldo Terkini!')
      }
      const tabunganData = {
        createAt: new Date(),
        userData: data,
        isTabungan: true,
        type: 'Masuk',
        totalAmount: addSaldo,
        name: 'Tabungan',
        status: 'done',
        id: tabunganId,
        updateAt: new Date(),
        tellerData: user,
        method: method,
        keterangan: keterangan
      };
      const updateData = {
        tabungan: {
          value: data.tabungan.value + addSaldo,
          updateAt: new Date()
        }
      };
      batch.set(tabunganHistory, tabunganData, { merge: true });
      batch.set(tabunganRef, tabunganData, { merge: true });
      batch.set(paymentHistoryRef, tabunganData, { merge: true });
      batch.update(santriRef, updateData);
      batch.commit();
      setTransData(tabunganData);
      setIsFinish(true);
      setIsLoading(false);
    } catch (error) {
      alert(error);
      console.log(error);
      setIsLoading(false);
    }
  };

  if (!data) {
    return (
      <CircularProgress color="inherit" />
    )
  }

  return (
    <div>
      <TextField
        id="name"
        label="Nama Santri"
        fullWidth
        value={data.name}
        contentEditable={false}
        margin="normal"
      />
      <TextField
        id="nomor"
        label="Nomor Rekening Santri"
        fullWidth
        value={data.noRekening}
        contentEditable={false}
        margin="normal"
      />
      <TextField
        id="value"
        label="Saldo Tabungan"
        fullWidth
        value={data.tabungan.value}
        contentEditable={false}
        margin="normal"
      />
      <TextField
        autoFocus
        margin="normal"
        id="id"
        label="Masukkan Nominal"
        fullWidth
        type='number'
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <TextField
        autoFocus
        margin="normal"
        id="keterangan"
        label="Keterangan"
        fullWidth
        value={keterangan}
        onChange={(e) => setKeterangan(e.target.value)}
      />
      <FormControl className={classes.formControl} fullWidth margin='normal'>
        <InputLabel>Metode Pembayaran</InputLabel>
        <Select
          value={method}
          onChange={(e) => setMethod(e.target.value)}
          label="Metode Pembayaran"
        >
          <MenuItem value={' - '}>
            <em>Pilih Metode Pembayaran</em>
          </MenuItem>
          <MenuItem value='Transfer'>Transfer</MenuItem>
          <MenuItem value='Tunai'>Tunai</MenuItem>
          <MenuItem value='Lebihan Pembayaran'>Lebihan Pembayaran</MenuItem>
          <MenuItem value='Dari Saku'>Dari Saku</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleAddSaldo}
        disabled={isLoading}
        fullWidth
      >
        Konfirmasi
      </Button>
      <ConfirmationDialog 
        open={isFinish} 
        handleClose={() => {
          setIsFinish(false);
          setOpen(false);
        }} 
        transData={transData}
        userData={data}
      />
    </div>
  );
}

export default Dettail;
