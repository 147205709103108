import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PrintTabungan from '../print';
import AddSaldo from '../addSaldo/Dialog';
import History from '../history';
import Withdraw from '../withdraw/Dialog';
import PaymentIcon from '@material-ui/icons/Payment';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';
import HistoryIcon from '@material-ui/icons/History';
import { Button } from '@material-ui/core';

export default function SimpleMenu({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openWD, setOpenWD] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {/* <IconButton onClick={handleClick}>
        <MenuIcon />
      </IconButton> */}
      <Button variant='contained' color='primary' onClick={handleClick}>
        Menu
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ListItem button onClick={() => setOpenAdd(true)}>
            <ListItemIcon>
              <AddToHomeScreenIcon />
            </ListItemIcon>
            <ListItemText primary="Tambah Saldo" />
          </ListItem>
        </MenuItem>
        <MenuItem>
          <ListItem button onClick={() => setOpenHistory(true)}>
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary="Riwayat" />
          </ListItem>
        </MenuItem>
        <MenuItem>
          <ListItem button onClick={() => setOpenWD(true)}>
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            <ListItemText primary="Tarik" />
          </ListItem>
        </MenuItem>
        {/* <MenuItem>
          <PrintTabungan data={data} /> 
        </MenuItem> */}
      </Menu>
      <Withdraw open={openWD} data={data} handleClose={() => setOpenWD(false)} /> 
      <AddSaldo open={openAdd} data={data} handleClose={() => setOpenAdd(false)} setOpen={setOpenAdd} />
      <History santriData={data} open={openHistory} handleClose={() => setOpenHistory(false)}  />
    </div>
  );
}
