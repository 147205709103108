// rematch
import { init } from '@rematch/core';
// import createLoadingPlugin from '@rematch/loading';
import persistPlugin from "@rematch/persist";
import immerPlugin from '@rematch/immer';
import storage from "redux-persist/lib/storage";

import * as models from '../models';

const persistConfig = {
  key: "bma_muhibin_rut",
  storage,
};

const immer = immerPlugin();

const store = init({
  models,
  plugins: [
    persistPlugin(persistConfig),
    immer
  ],
});

export default store;
