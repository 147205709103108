import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  DialogContentText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import AddTable from './addSantri';
import { firestore } from 'src/config/firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(7),
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

function AddSantriAction({ payData, data }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const addPayment = async () => {
    setLoading(true);
    const setPaymentData = payData;
    try {
      const setSantriTest = (
        await firestore.collection('santri').get()
      )
      setSantriTest.forEach(async (doc) => {
        await firestore.collection('santri').doc(`${doc.data().noRekening}`).collection('payment').doc(`${data.id}`)
          .set(data, { merge: true });
        await firestore.collection('santri').doc(`${doc.data().noRekening}`)
          .collection('payment').doc(`${data.id}`)
          .collection('list-payment').doc(`${setPaymentData.id}`)
            .set(setPaymentData, { merge: true })
      })
      console.log('done')
      setLoading(false);
      alert('Sukses');
      setOpenConfirm(false);
      handleClose();
    } catch (error) {
      console.log(error);
      alert(error);
      setLoading(false);
    }
  };
  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Tambah Santri
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Atur Pembayaran</DialogTitle>
        <DialogContent>
          <div style={{position: 'absolute', right: 50, top: 10}}>
            <Button variant="contained" color="primary" onClick={() => setOpenConfirm(true)}>
              Pilih Semua
            </Button>
          </div>
          {
            loading
              ? <CircularProgress />
              : <AddTable payData={payData} data={data} />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Atur Pembayaran</DialogTitle>
        <DialogContent>
          {
            loading
              ? <CircularProgress />
              : <DialogContentText>
                  Apakah Anda Yakin Ingin Memilih Semua Santri ?
                </DialogContentText>
          }
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} variant="outlined" onClick={() => setOpenConfirm(false)} color="secondary">
            Batal
          </Button>
          <Button disabled={loading} variant="contained" onClick={addPayment} color="primary">
            Ya, Pilih Semua
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddSantriAction;
