import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { firestore } from 'src/config/firebase';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function FormDialog({open, handleClose, data}) {
  const classes = useStyles();

  const [name, setname] = useState(data.name);
  const [alamat, setAlamat] = useState(data.alamat);
  const [ayah, setAyah] = useState(data.ayah);
  const [ibu, setIbu] = useState(data.ibu);
  const [kamar, setKamar] = useState(data.kamar);
  const [kelamin, setKelamin] = useState(data.kelamin);
  const [kontakAyah, setKontakAyah] = useState(data.kontakAyah);
  const [kontakIbu, setKontakIbu] = useState(data.kontakIbu);
  const [noRekening, setNoRekening] = useState(data.noRekening);
  const [sekolah, setSekolah] = useState(data.sekolah);
  const [tanggalLahir, setTanggalLahir] = useState(_.isString(data.tanggalLahir) ? data.tanggalLahir : moment(data.tanggalLahir.toDate()).format("DD MM YYYY"));
  const [tempatLahir, setTempatLahir] = useState(data.tempatLahir);
  const [loading, setLoading] = useState(false);
  const [ime, setIme] = useState(data.ime === undefined ? '' : data.ime);
  const [isActive, setIsActive] = useState(!data.isActive ? false : data.isActive);

  const handleSave = async () => {
    setLoading(true)
    const ref = firestore.collection('santri').doc(`${noRekening.trim()}`)
    try {
      await ref.set({
        alamat: alamat,
        name: name,
        ayah: ayah,
        ibu: ibu,
        kamar: kamar,
        kelamin: kelamin,
        kontakAyah: kontakAyah,
        kontakIbu: kontakIbu,
        noRekening: noRekening,
        sekolah: sekolah,
        tanggalLahir: tanggalLahir,
        tempatLahir: tempatLahir,
        updateAt: new Date(),
        ime: ime,
        isActive: isActive
      }, { merge: true })
      alert('Berhasil Menyimpan Data Santri');
      handleClose();
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert(error);
      setLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"  maxWidth='lg' fullWidth>
      <DialogTitle id="form-dialog-title">Input Data Santri</DialogTitle>
      <DialogContent>
      <Grid container justifyContent='space-around' alignItems='center' spacing={2}>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              id="noRekening"
              label="No. Induk"
              fullWidth
              value={noRekening}
              onChange={(e) => setNoRekening(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="ime"
              label="No. IME"
              fullWidth
              value={ime}
              onChange={(e) => setIme(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nama"
              fullWidth
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="alamat"
              label="Alamat"
              multiline
              fullWidth
              value={alamat}
              onChange={(e) => setAlamat(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="kelamin"
              label="Jenis Kelamin"
              fullWidth
              value={kelamin}
              onChange={(e) => setKelamin(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="kamar"
              label="Kamar"
              fullWidth
              value={kamar}
              onChange={(e) => setKamar(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              id="sekolah"
              label="Sekolah"
              fullWidth
              value={sekolah}
              onChange={(e) => setSekolah(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="tempatLahir"
              label="Tempat Lahir"
              fullWidth
              value={tempatLahir}
              onChange={(e) => setTempatLahir(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="tanggalLahir"
              label="Tanggal Lahir"
              fullWidth
              value={tanggalLahir}
              onChange={(e) => setTanggalLahir(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="ayah"
              label="Ayah"
              fullWidth
              value={ayah}
              onChange={(e) => setAyah(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="kontakAyah"
              label="Kontak Ayah"
              fullWidth
              value={kontakAyah}
              onChange={(e) => setKontakAyah(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="ibu"
              label="Ibu"
              fullWidth
              value={ibu}
              onChange={(e) => setIbu(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="kontakIbu"
              label="Kontak Ibu"
              fullWidth
              value={kontakIbu}
              onChange={(e) => setKontakIbu(e.target.value)}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
              label={`Status: ${isActive ? 'Aktif' : 'Boyong'}`}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave} color="primary">
          Simpan
        </Button>
      </DialogActions>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}
