import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector } from 'react-redux';

import { firestore } from 'src/config/firebase';

const paymentCollection = 'paymentHistory';

export default function FormDialog({ open, handleClose, row }) {
  const dataSantri = row.userData;
  const teller = useSelector(state => state.user.profile);
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);

  const handleRejectPayment = async () => {
    try {
      setLoading(true);
      const batch = firestore.batch();
      const santriRef = firestore.collection('santri').doc(`${dataSantri.noRekening}`)
      const paymentHistoryRef = firestore.collection(paymentCollection).doc(`${row.id}`)
      const santriHistoryRef = santriRef.collection(paymentCollection).doc(`${row.id}`)
      const rejectData = {
        rejectReason: reason,
        rejectAt: new Date(),
        status: 'reject',
        tellerData: teller
      }
      batch.set(paymentHistoryRef, rejectData, { merge: true })
      batch.set(santriHistoryRef, rejectData, { merge: true })
      await batch.commit();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(true);
    }
  } 

  return ( 
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Konfirmasi Tindakan</DialogTitle>
      <DialogContent>
        <DialogContentText>
          { `Apakah Anda Yakin Untuk Menolak Pembayaran ${row.userData.name}` }
        </DialogContentText>
        <TextField
          autoFocus
          margin="normal"
          label="Alasan Penolakan"
          fullWidth
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button 
          variant='outlined' 
          onClick={handleClose} 
          color="secondary"
          disabled={loading}
        >
          Batal
        </Button>
        <Button 
          onClick={handleRejectPayment} 
          variant='contained' 
          color="primary"
          disabled={loading}
        >
          { loading ? 'menyimpan...' : 'Konfirmasi' }
        </Button>
      </DialogActions>
    </Dialog>
  );
}
