import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { firestore } from 'src/config/firebase';
import { useSelector } from 'react-redux';

const paymentCollection = 'paymentHistory';
const sakuCollection = 'sakuHistory';
const tabunganCollection = 'tabunganHistory';

export default function FormDialog({ open, handleClose, row }) {
  const dataSantri = row.userData;
  const paymentData = row.paymentDataId;
  const teller = useSelector(state => state.user.profile);
  const [loading, setLoading] = useState(false);

  const handleConfirmPayment = async () => {
    try {
      setLoading(true);
      const batch = firestore.batch();
      const santriRef = firestore.collection('santri').doc(`${dataSantri.noRekening}`)
      const paymentRef = santriRef.collection('payment').doc(`${row.paymentID}`)
      const paymentHistoryRef = firestore.collection(paymentCollection).doc(`${row.id}`)
      const santriHistoryRef = santriRef.collection(paymentCollection).doc(`${row.id}`)
      const confirmData = {
        confirmAt: new Date(),
        status: 'done',
        tellerData: teller
      }
      paymentData.forEach((a) => {
        batch.set(paymentRef.collection('list-payment').doc(`${a.id}`), a, { merge: true });
      })
      if (row.saku !== null) {
        const dataSaldo = (
          await santriRef.get()
        ).data().saku.value;
        const sakuHistory = santriRef.collection(`${sakuCollection}`).doc(`${row.id}`);
        const sakuRef = firestore.collection(`${sakuCollection}`).doc(`${row.id}`);
        const sakuData = {
          createAt: new Date(),
          userData: dataSantri,
          isSaku: true,
          type: 'Masuk',
          totalAmount: row.saku.totalAmount,
          name: 'Uang Saku',
          status: 'done',
          id: row.id,
          updateAt: new Date(),
          tellerData: teller,
          method: 'Transfer Via Aplikasi'
        };
        const updateData = {
          saku: {
            value: dataSaldo + row.saku.totalAmount,
            updateAt: new Date()
          }
        };
        batch.set(sakuHistory, sakuData, { merge: true });
        batch.set(sakuRef, sakuData, { merge: true });
        batch.update(santriRef, updateData);
      }
      if (row.tabungan !== null) {
        const dataSaldo = (
          await santriRef.get()
        ).data().tabungan.value;
        const tabunganHistory = santriRef.collection(`${tabunganCollection}`).doc(`${row.id}`);
        const tabunganRef = firestore.collection(`${tabunganCollection}`).doc(`${row.id}`);
        const tabunganData = {
          createAt: new Date(),
          userData: dataSantri,
          isTabungan: true,
          type: 'Masuk',
          totalAmount: row.tabungan.totalAmount,
          name: 'Tabungan',
          status: 'done',
          id: row.id,
          updateAt: new Date(),
          tellerData: teller,
          method: 'Transfer Via Aplikasi'
        };
        const updateData = {
          tabungan: {
            value: dataSaldo + row.tabungan.totalAmount,
            updateAt: new Date()
          }
        };
        batch.set(tabunganHistory, tabunganData, { merge: true });
        batch.set(tabunganRef, tabunganData, { merge: true });
        batch.update(santriRef, updateData);
      }
      batch.set(paymentHistoryRef, confirmData, { merge: true })
      batch.set(santriHistoryRef, confirmData, { merge: true })
      await batch.commit();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(true);
    }
  } 

  return ( 
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Konfirmasi Tindakan</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Apakah Anda Yakin Untuk Memverifikasi Pembayaran ${row.userData.name}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          variant='outlined' 
          onClick={handleClose} 
          color="secondary"
          disabled={loading}
        >
          Batal
        </Button>
        <Button 
          onClick={handleConfirmPayment} 
          variant='contained' 
          color="primary"
          disabled={loading}
        >
          { loading ? 'menyimpan...' : 'Konfirmasi' }
        </Button>
      </DialogActions>
    </Dialog>
  );
}
