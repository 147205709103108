import React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  TableHeaderRow,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import 'moment/locale/id';

import TunggakanButton from './arrears';
import CountSantri from './components/santriCount';

const columns = [
  {
    name: 'name',
    title: 'Nama',
    getCellValue: (row) => row.name === undefined ? '' : row.name.toUpperCase()
  },
  {
    name: 'keterangan',
    title: 'Santri yang Menunggak',
    getCellValue: (row) => <CountSantri rowData={row} />
  },
  {
    name: 'arrears',
    title: 'Rincian',
    getCellValue: (row) => <TunggakanButton rowData={row} />
  },
];

const Tables = ({ data }) => {
  return (
    <Paper>
      <Grid
        rows={data}
        columns={columns}
      >
        <SearchState />
        <PagingState
          defaultCurrentPage={0}
          pageSize={5}
        />
        <IntegratedFiltering />
        <IntegratedPaging />
        <Table />
        <TableHeaderRow />
        <PagingPanel />
        <Toolbar />
        <SearchPanel />
      </Grid>
    </Paper>
  );
};

export default Tables;
