import React, { useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  LinearProgress
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import Page from 'src/components/Page';
import Results from './Results';
import data from './data';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
  }
}));

const CustomerListView = () => {
  const santri = useSelector(state => state.santri.santri);
  const classes = useStyles();
  const [customers] = useState(data);

  if (santri === null) {
    return (
      <LinearProgress color='primary' />
    )
  }

  return (
    <Page
      className={classes.root}
      title="Santri"
    >
      <Container maxWidth={false}>
        <Box mt={3}>
          <Results customers={customers} santri={santri.santri} />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
